import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAtcList } from 'redux/modules/atc';
import Checkbox from 'components/base/Checkbox';
import FormField from 'components/base/FormField';
import Spinner from 'components/base/Spinner';

// https://s1-fmt2.liveatc.net/ktpa2?nocache=2024091811294435314
// https://s1-bos.liveatc.net/kpie1_app_118800?nocache=2024091811300555615
// const streamUrl = 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3';
// url: 'https://archive.liveatc.net/katw/KATW2-Twr-Sep-17-2024-1600Z.mp3',

const LiveFeedForm = () => {
  const dispatch = useDispatch();
  const { fetching, loaded, data = [] } = useSelector((state) => state.atc.live);
  const [selectedRadioCode, setSelectedRadioCode] = useState();

  useEffect(() => {
    if (!loaded) {
      dispatch(fetchAtcList());
    }
  }, [loaded, dispatch]);

  return (
    <div className="atc__block atc__feed">
      {fetching ? (
        <Spinner className="atc__spinner" />
      ) : (
        <>
          <div className="atc__feed-list">
            {data.map(({ code, url, name }) => {
              return (
                <FormField mods={{ row: true, largeLabel: true }} label={name} name={code} key={code}>
                  <Checkbox
                    label={name}
                    type="radio"
                    name="atc-feed"
                    id={code}
                    value={code}
                    checked={selectedRadioCode === code}
                    onChange={(e) => {
                      setSelectedRadioCode(code);
                    }}
                    disabled={!url}
                  />
                </FormField>
              );
            })}
          </div>
          {data.map(({ code, url, name }) => {
            if (!url) return null;
            return (
              <audio
                controls
                className={clsx('atc__feed-player', {
                  'atc__feed-player_visible': selectedRadioCode === code,
                })}
                title={name}
                key={code}
              >
                <source src={url} type="audio/mp3" />
                Your browser does not support the audio element.
              </audio>
            );
          })}
        </>
      )}
    </div>
  );
};

export default LiveFeedForm;
