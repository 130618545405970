import React, { lazy } from 'react';
import Page from 'components/common/Page';
const BasedAircrafts = lazy(() => import('./BasedAircrafts'));

const BasedAircraftsPage = () => {
  return (
    <Page>
      <BasedAircrafts />
    </Page>
  );
};

export default BasedAircraftsPage;
