import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from 'components/common/ErrorBoundary';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { SocketContext } from 'api/SocketContext';
import { SocketService } from 'api/SocketService';

import App from './App';
import createStore from './redux/createStore';

import './static/fonts/fonts.css';
import './index.css';

// eslint-disable-next-line react/jsx-no-constructed-context-values
const socketService = new SocketService();

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <SocketContext.Provider value={socketService}>
        <DndProvider backend={HTML5Backend}>
          <Provider store={createStore()}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Provider>
        </DndProvider>
      </SocketContext.Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
