import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getAirportTimeFormatter, getAirportTimeLocalBrowserFormatter } from 'utils/dateTimeHelpers';

import '../Header.css';

const Time = ({ name, utc = false, localBrowserTime = false }) => {
  const [time, setTime] = useState('00:00');
  const { timezone } = useSelector((state) => state.auth);

  const timeFormatter = useMemo(() => {
    return utc
      ? getAirportTimeFormatter()
      : localBrowserTime
      ? getAirportTimeLocalBrowserFormatter()
      : getAirportTimeFormatter(timezone);
  }, [timezone, utc, localBrowserTime]);

  useEffect(() => {
    if (timeFormatter) setTime(timeFormatter.format(new Date()));

    const intervalId = setInterval(() => {
      if (timeFormatter) setTime(timeFormatter.format(new Date()));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [timezone, timeFormatter]);

  return (
    <div className="header-item">
      <div className="header-item__inner">
        <div className="header-item__top">{name}</div>
        <div className="header-item__bottom">{time}</div>
      </div>
    </div>
  );
};

Time.propTypes = {
  name: PropTypes.string,
  utc: PropTypes.bool,
  localBrowserTime: PropTypes.bool,
};

export default Time;
