import React, { lazy } from 'react';
import Page from 'components/common/Page';
const GeofencesEdit = lazy(() => import('./GeofencesEdit'));

const GeofencesEditPage = () => {
  return (
    <Page>
      <GeofencesEdit />
    </Page>
  );
};

export default GeofencesEditPage;
