import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Slide, ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import MenuButton from './components/MenuButton';
import NavigationList from './components/NavigationList';
import SideModal from './components/SideModal';
import { navigationCommonList, navigationGroupViewList, navigationSuperAdminList } from '../../../links';

import 'react-toastify/dist/ReactToastify.css';
import 'styles/notifications.css';
import './SideNavigation.css';

const SideNavigation = ({ className, children, hiddenSidebarButton = false, showBottomBar }) => {
  const [isOpen, setOpen] = useState(!hiddenSidebarButton);
  const { currentSidebarModal } = useSelector((state) => state.modal);

  const toggleMenu = () => {
    setOpen((open) => !open);
    // Dispatch resize event to force map recalculate its width
    window.dispatchEvent(new Event('resize'));
  };

  return (
    <div className="navigation__container">
      <nav
        className={clsx('navigation', className, {
          navigation_open: isOpen,
          navigation_hidden: currentSidebarModal,
          'navigation_hidden-button': hiddenSidebarButton,
        })}
        aria-labelledby="mainmenulabel"
      >
        <MenuButton className="navigation_menu-button" onClick={toggleMenu} />
        <h2 id="mainmenulabel" className="visuallyhidden">
          Main Menu
        </h2>
        <div
          className={clsx('navigation-scrollable', {
            navigation_open: isOpen,
          })}
        >
          <NavigationList navigationList={navigationCommonList} />
          <NavigationList navigationList={navigationSuperAdminList} delimiter />
          <NavigationList navigationList={navigationGroupViewList} delimiter />
        </div>
      </nav>
      <div className={clsx('navigation__content', { 'navigation__content_bottom-bar': showBottomBar })}>
        {children}
        <ToastContainer
          position="bottom-right"
          transition={Slide}
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          limit={7}
        />
      </div>
    </div>
  );
};

SideNavigation.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  hiddenSidebarButton: PropTypes.bool,
  showBottomBar: PropTypes.bool,
};

export { SideModal };
export default SideNavigation;
