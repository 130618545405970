import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import timezones from 'timezones.json';
import api from 'api/api';
import { getUserPermissionsList, permissions } from 'utils/userPermissions';

const defaultTimezone = timezones.find((e) => e.value === 'UTC');

/**
 * @typedef {Object} AuthState
 * @property {boolean} fetched
 * @property {Object} userInfo
 *  "id": 68,
    "username": "KPGD",
    "permissionsList": [
        "PAGE_DEFAULT",
        "OPTION_DEFAULT",
        "PAGE_NOISE_ABATEMENT",
        "PAGE_SCHEDULED_FLIGHTS"
    ],
    "active": true,
    "firstName": "",
    "email": "",
    "icao": "KPGD",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IktQR0QiLCJwZXJtaXNzaW9ucyI6ImFkbWluIiwiaWNhbyI6IktQR0QiLCJpYXQiOjE2NDg3NDA4NDMsImV4cCI6MTY2NDI5Mjg0M30.tkzJPZd0daKVnQjsF-xSQQInfVoEXTjbj2_L6B3n0LU",
    "isDisclaimerRead": true,
 * @property {Object} timezone {"value": "Eastern Standard Time","abbr": "EDT","offset": -4,"isdst": true,"text": "(UTC-05:00) Eastern Time (US & Canada)","utc": ["America/Detroit",]
 * @property {Object} userSettings 
 * "lang": "en",
    "copy": "© Virtower 2022",
    "supportText": "We are here to help.  \nIn case you would have questions or require support,  \nplease feel free to send us an email or give us a call.\n\nVirtower LLC  \n13721 JETPORT COMMERCE PKWY STE 2  \nFORT MYERS, 33913  \n\nEmail  \n[info@virtower.com](mailto:info@virtower.com)\n\nPhone  \n[+1 888 31 70 747](tel:+18883170747)\n\n(Operating Hours Monday to Friday 9.00 AM to 5.00 PM, Local Time)  \nFor extended availability please refer to your service contract.",
    "version": "Version:  \n1.32.1\n",
    "reportAddress": "**VirTower LLC**  \n13721 Jetport Commerce Pkwy, Suite 2  \nFort Myers FL 33913  \nPhone +1 888 31 70 747  \nvirtower.com | info@virtower.com",
    "reportRemark": "This report was generated using sensors monitoring aircraft operations at the selected airport and may not contain aircraft that do not have ADS-B. Airports that have multiple sensors deployed will also feature aircraft fitted with transponders only. The information presented is correct to the best of our knowledge from available sensors at the time: Les Goldsmith, President VirTower LLC",
    "scheduledHelpText": ""
 * @property {Object} airport full data of airport, do we really need that? TODO!: define what do we need
 * 
 */

const initialState = {
  fetched: false,
  userInfo: {
    isLoggedIn: false,
    permissionsList: [],
    username: '',
    icao: '',
    isDisclaimerRead: false,
    isDebugMode: false,
  },
  timezone: defaultTimezone,
  userSettings: {
    copy: 'virtower',
  },
};

export const logIn = createAsyncThunk('auth/login', async (values) => {
  const userInfo = await api.logIn(values);
  return { userInfo };
});

export const loginAs = createAsyncThunk('auth/login-as', async (username) => {
  await api.loginAs(username);
  localStorage.setItem(`${username}_disclaimer_read`, true);
  window?.location?.reload();
});

export const loginTotp = createAsyncThunk('auth/totp', async ({ totpToken }) => {
  await api.loginTotp(totpToken);

  return;
});

export const fetchUser = createAsyncThunk('users/fetchUser', async () => {
  const data = await api.getCurrentUser();

  return data;
});

export const updateCustomerMetaf = createAsyncThunk('customers/updateMetaf', async (customer) => {
  return api.updateCustomerMetaf(customer);
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setDisclaimerRead: (state, payload) => {
      localStorage.setItem(`${state.userInfo.username}_disclaimer_read`, true);
      state.userInfo.isDisclaimerRead = payload;
    },
  },
  extraReducers: {
    [updateCustomerMetaf.fulfilled]: (state, action) => {
      state.airport.metarIcao = action.payload.metarIcao;
    },
    [fetchUser.fulfilled]: (state, action) => {
      const authPayload = action.payload;
      const {
        username,
        permissionLevel,
        debugMode,
        airport,
        userSettings,
        operationsMetarIcaoChanges,
        token,
        firstName,
        id,
        airportId,
        customerGroup,
        isServiceUser,
      } = authPayload;

      const { icao, hasFeatureNoiseAbatement, hasFeatureScheduledFlights, fromHours, toHours, daysPerWeek, time_zone } =
        airport;
      const permissionFlags = { debugMode, hasFeatureNoiseAbatement, hasFeatureScheduledFlights };
      const permissionsList = getUserPermissionsList(permissionLevel, permissionFlags);
      const isDisclaimerRead = Boolean(localStorage.getItem(`${username}_disclaimer_read`));
      const isDebugMode = permissionsList.includes(permissions.OPTION_DEBUG);
      const customerGroupName = customerGroup?.name;
      const isLoggedIn = true; // because we have all of the data already

      state.userInfo = {
        token, // for websocket
        username, // for header
        icao, // for header
        customerGroupName, // for header, replacement of ICAO for group viewer
        permissionsList, // for checking routes and links
        isDisclaimerRead, // for disclaimer page
        isLoggedIn, // for route sorting
        isDebugMode, // for additional feature
        firstName, // for disclaimer
        id, // just for debug
        airportId, // just for debug
        isServiceUser, // to show correct ICAO for service users
      };
      state.airport = airport; // contains only required fields, all good
      state.operationsMetarIcaoChanges = operationsMetarIcaoChanges; // used for reports generation, to specify which airport weather was at moment of operation if there are several of them.
      const isFullWeekWorking = daysPerWeek === 7;
      const isWorkingUpToMidnight =
        (fromHours === '00:00' && toHours === '23:59') || (fromHours === '00:01' && toHours === '24:00');
      state.isAfterHoursAvailable = !isFullWeekWorking || !isWorkingUpToMidnight;

      state.timezone = timezones.find((e) => e.value === time_zone) || defaultTimezone;
      state.userSettings = userSettings;
      state.fetched = true;
    },
    [fetchUser.rejected]: () => {
      return { ...initialState, fetched: true };
    },
    [logIn.fulfilled]: (state, action) => {
      const { userInfo } = action.payload;
      const { totpQRcode, totpSecret, isTotpEnabled } = userInfo;
      const isLoggedIn = false;
      state.userInfo = {
        ...state.userInfo,
        isLoggedIn,
        totpQRcode,
        totpSecret,
        isTotpEnabled,
      };
    },
    [logIn.rejected]: (state, action) => {
      console.error('[ERROR] Cannot log in', action);
    },
    [loginTotp.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
    },
  },
});

export default authSlice.reducer;
export const { setDisclaimerRead } = authSlice.actions;
