import { createSlice } from '@reduxjs/toolkit';
import { ChartNames, ChartTypes } from 'utils/statistic.utils';
import { getMonthsAgoRangeValues } from 'utils/dateTimeHelpers';

/**
 * @typedef {Object} StatisticParams
 * @property {string} dateFrom: "2020-09-01T00:00:00.000Z"
 * @property {string} dateTo: "2020-10-01T00:00:00.000Z"
 * @property {string} dateFormat: utc | local | localAfterHours
 * @property {string} airportFromHours: 05:00
 * @property {string} airportToHours: 19:00
 *
 */

/**
 * @typedef {Object} StatisticData
 * @property {string} chartType: line (line|bar|pie)
 * @property {string} dataFetched: false
 * @property {Object[]} data: Array of objects with data
 */

/**
 * @typedef {Object} StatisticState
 * @property {StatisticParams[]} params - Contains list of operations which indicates flight status
 * @property {StatisticData[]} data - Contains list of operations which indicates alarms
 */

const { startDateTime: defaultStartDateTime, endDateTime: defaultEndDateTime } = getMonthsAgoRangeValues(1);

const defaultParams = {
  startDateTime: defaultStartDateTime,
  endDateTime: defaultEndDateTime,
  dateFormat: 'local',
};

const initialState = {
  charts: {
    [ChartNames.OPS_PER_VISITING]: {
      data: [],
      dataFetched: false,
      chartType: ChartTypes.PIE,
      params: { ...defaultParams },
    },
    [ChartNames.OPS_PER_HOUR]: {
      chartType: ChartTypes.LINE,
      params: { ...defaultParams },
    },
    [ChartNames.OPS_PER_ICON_TYPE]: {
      chartType: ChartTypes.BAR,
      params: { ...defaultParams },
    },
    [ChartNames.OPS_PER_DOW]: {
      chartType: ChartTypes.BAR,
      params: { ...defaultParams },
    },
    [ChartNames.OPS_PER_RWY]: {
      chartType: ChartTypes.PIE,
      params: { ...defaultParams },
    },
    [ChartNames.OPS_PER_RWY_TO]: {
      chartType: ChartTypes.PIE,
      params: { ...defaultParams },
    },
    [ChartNames.OPS_PER_RWY_LDG]: {
      chartType: ChartTypes.PIE,
      params: { ...defaultParams },
    },
  },
};

export const statisticSlice = createSlice({
  name: 'statistic',
  initialState,
  reducers: {
    setStatisticParams(state, action) {
      const { chartName, ...params } = action.payload;
      const chart = state.charts[action.payload.chartName];
      if (chart) chart.params = params;
    },
    setChartType(state, action) {
      const chart = state.charts[action.payload.chartName];
      if (chart) chart.chartType = action.payload.chartType;
    },
    setChartData(state, action) {
      const chart = state.charts[action.payload.chartName];
      if (chart) chart.data = action.payload.data;
    },
    setChartDataFetched(state, action) {
      const chart = state.charts[action.payload.chartName];
      if (chart) chart.dataFetched = action.payload.dataFetched;
    },
  },
});

export default statisticSlice.reducer;

export const { setStatisticParams, setChartType, setChartData, setChartDataFetched } = statisticSlice.actions;
