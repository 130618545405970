import React from 'react';
import PropTypes from 'prop-types';

const TableFilter = ({ column: { filterValue, filterable = true, filterInputType }, setFilter }) => {
  return (
    <input
      className="input"
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      disabled={!filterable}
      type={filterInputType}
    />
  );
};

TableFilter.propTypes = {
  column: PropTypes.shape({
    filterValue: PropTypes.string,
    filterable: PropTypes.bool,
    filterInputType: PropTypes.string,
  }).isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default TableFilter;
