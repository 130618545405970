import React, { lazy } from 'react';
import Page from 'components/common/Page';
const NoiseAbatement = lazy(() => import('./NoiseAbatement'));

const NoiseAbatementPage = () => {
  return (
    <Page fullscreen>
      <NoiseAbatement />
    </Page>
  );
};

export default NoiseAbatementPage;
