import React, { lazy } from 'react';
import Page from 'components/common/Page';
const Customers = lazy(() => import('./Customers'));

const CustomersPage = () => {
  return (
    <Page>
      <Customers />
    </Page>
  );
};

export default CustomersPage;
