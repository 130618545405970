// prettier-ignore
export const aircraftListTupleToObject = (tuple) => {
  const [id, registration, pickedUpFlight, type, faaCategory, aircraftDesignGroup, operatorDetails, basedIcaoList, isArchived, iconType, modeS] = tuple;
  return {id, registration, pickedUpFlight, type, faaCategory, aircraftDesignGroup, operatorDetails, basedIcaoList, isArchived, iconType, modeS};
}

// prettier-ignore
export const aircraftListObjectToTuple = (obj) => {
  const {id, registration, pickedUpFlight, type, faaCategory, aircraftDesignGroup, operatorDetails, basedIcaoList, isArchived, iconType, modeS} = obj;
  return [id, registration, pickedUpFlight, type, faaCategory, aircraftDesignGroup, operatorDetails, basedIcaoList, isArchived, iconType, modeS] 
}
