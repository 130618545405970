import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from './modules';

export default function createStore(preloadedState) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
  });
}
