/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { setFilter, setSort, setPage } from 'redux/modules/tables';
import TableBatchEdit from 'pages/Aircrafts/TableBatchEdit';
import Spinner from 'components/base/Spinner';
import Pagination from './Pagination';
import { TableFilter } from './TableFilters';

import './CustomTable.css';

const getNextState = (currState) => {
  if (currState === undefined) return 'asc';

  return currState ? undefined : 'desc';
};

const CustomTable = forwardRef(
  (
    {
      className,
      tableName,
      tableInstance: { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, ...rest },
      pagination = false,
      filters = false,
      sorting = false,
      onRowClick = undefined,
      ExpandedComponent,
      batchEdit,
      batchEditFunc,
      renderActions,
      loading,
      emptyTableText,
    },
    activeRowRef
  ) => {
    const dispatch = useDispatch();

    const getPageRows = useMemo(() => (pagination ? rest.page : rows), [pagination, rest.page, rows]);

    const handleFilterChange = (column, value) => {
      dispatch(
        setPage({
          tableName,
          value: 0,
        })
      );

      dispatch(
        setFilter({
          tableName,
          column: column.id,
          value,
        })
      );

      if (column) column.setFilter(value);
    };

    const handleHeaderClick = (column) => {
      if (column.disableSortBy) return;
      const isColumnSortPossible = sorting && column && column.toggleSortBy;

      if (isColumnSortPossible) column.toggleSortBy();

      dispatch(
        setSort({
          tableName,
          column: column.id,
          value: getNextState(column.isSortedDesc),
        })
      );
    };

    const handlePageChange = (newPage) => {
      dispatch(
        setPage({
          tableName,
          value: newPage,
        })
      );
    };

    const columnHasFilter = (column) => column.canFilter && column.filter;
    const columnHasCustomFilter = (column) => Boolean(column.Filter);

    const renderActionsJsx = () => {
      if (!renderActions) return null;
      const actionsList = renderActions(rows.map((r) => r.original));
      if (!actionsList?.length) return null;

      return (
        <div className="table-btn-group">
          {actionsList.map((child) =>
            React.cloneElement(child, {
              className: `${child.props.className} table-btn-group__btn`,
              key: child.props.children,
            })
          )}
        </div>
      );
    };

    const renderTableFooter = () => {
      return pagination || batchEditFunc || renderActions ? (
        <div className={clsx('table__footer')}>
          {pagination && (
            <Pagination
              canPreviousPage={rest.canPreviousPage}
              canNextPage={rest.canNextPage}
              gotoPage={rest.gotoPage}
              previousPage={rest.previousPage}
              nextPage={rest.nextPage}
              pageCount={rest.pageCount}
              pageIndex={rest.state.pageIndex}
              pageOptions={rest.pageOptions}
              handlePageChange={handlePageChange}
            />
          )}
          {batchEdit && <TableBatchEdit rows={rest.selectedFlatRows} />}
          {batchEditFunc && batchEditFunc(rest.selectedFlatRows)}
          {renderActionsJsx()}
        </div>
      ) : null;
    };

    return (
      <>
        <table {...getTableProps()} className={clsx('table', className)}>
          <thead className="table-head">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="table-head__row">
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(sorting && column.getSortByToggleProps())}
                    style={{
                      width: column.width,
                      padding: column.noPadding && '0',
                      minWidth: column.minWidth,
                    }}
                    className={clsx('table-head__cell', {
                      'table-head__cell_sort': column.sortable,
                      'table-head__cell_sort_asc': column.sortable && column.isSorted && !column.isSortedDesc,
                      'table-head__cell_sort_desc': column.sortable && column.isSorted && column.isSortedDesc,
                      'table-head__cell_center': column.center,
                    })}
                    onClick={() => handleHeaderClick(column)}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
            {filters &&
              headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className="table-head__row">
                  {headerGroup.headers.map((column) => (
                    <th
                      className="table-head__cell table-head__cell_filter"
                      style={{
                        width: column.width,
                        padding: column.noPadding && '0',
                        minWidth: column.minWidth,
                      }}
                      {...column.getHeaderProps()}
                    >
                      {columnHasFilter(column) && !columnHasCustomFilter(column) && (
                        <TableFilter column={column} setFilter={(value) => handleFilterChange(column, value)} />
                      )}
                      {columnHasCustomFilter(column) && columnHasCustomFilter(column) && column.render('Filter')}
                      {column.Button && column.render('Button')}
                    </th>
                  ))}
                </tr>
              ))}
          </thead>
          <tbody {...getTableBodyProps()} className="table-body">
            {loading && (
              <tr className="table-body__loader-tr">
                <td>
                  <Spinner
                    className={clsx('table-body__loader', {
                      'table-body__loader_overlay': getPageRows?.length,
                    })}
                  />
                </td>
              </tr>
            )}
            {getPageRows?.length
              ? getPageRows.map((row) => {
                  prepareRow(row);
                  return (
                    <React.Fragment key={`row-${row.id}`}>
                      <tr
                        {...row.getRowProps()}
                        onClick={() => onRowClick && onRowClick(row)}
                        className={clsx({
                          'table-body__row': true,
                          'table-body__row-archived': row.original.isArchived,
                          'row-expanded': row.isExpanded,
                          'table-body__row-active': row.original.rowActive,
                          'table-body__loading': loading,
                        })}
                        ref={row.original.rowActive ? activeRowRef : null}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td
                              style={{
                                width: cell.column.width,
                                padding: cell.column.noPadding && '0',
                                minWidth: cell.column.minWidth,
                                overflow: cell.column.collapse && 'visible',
                              }}
                              {...cell.getCellProps()}
                              className={clsx('table-body__cell', {
                                'table-body__cell_nowrap': cell.column.noWrap,
                                'table-body__cell_center': cell.column.center,
                              })}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                      {row.isExpanded && (
                        <tr className="table-body__row row-expanded__info">
                          <td className="table-body__cell">
                            {ExpandedComponent && <ExpandedComponent entity={row.original} />}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })
              : !loading &&
                emptyTableText && (
                  <tr className="table-body__row_empty">
                    <td className="table-body__cell table-body__cell_empty">{emptyTableText}</td>
                  </tr>
                )}
          </tbody>
        </table>
        {renderTableFooter()}
      </>
    );
  }
);

CustomTable.propTypes = {
  className: PropTypes.string,
  tableName: PropTypes.string.isRequired,
  tableInstance: PropTypes.shape({
    getTableProps: PropTypes.func,
    getTableBodyProps: PropTypes.func,
    headerGroups: PropTypes.arrayOf(
      PropTypes.shape({
        width: PropTypes.number,
        minWidth: PropTypes.number,
        collapse: PropTypes.bool,
        noPadding: PropTypes.bool,
        filter: PropTypes.string,
        sortable: PropTypes.bool,
        center: PropTypes.bool,
      })
    ),
    rows: PropTypes.arrayOf(PropTypes.shape({})),
    prepareRow: PropTypes.func,
  }).isRequired,
  onRowClick: PropTypes.func,
  pagination: PropTypes.bool,
  filters: PropTypes.bool,
  sorting: PropTypes.bool,
  ExpandedComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  batchEdit: PropTypes.bool,
  renderActions: PropTypes.func,
  batchEditFunc: PropTypes.func,
  loading: PropTypes.bool,
  emptyTableText: PropTypes.string,
};

export default CustomTable;
