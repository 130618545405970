import timezones from 'timezones.json';
import countries from './countries.json';

export const fillNullFieldsWithDefaultValues = (entity, defaultValues) => {
  const newValues = { ...entity };
  Object.keys(entity).forEach((key) => {
    if (entity[key] === null) newValues[key] = defaultValues[key];
  });

  return newValues;
};

export const fillEmptyValuesWithNull = (values = {}) => {
  Object.keys(values).forEach((key) => {
    if (values[key] === '') values[key] = null;
  });

  return values;
};

export const fuelTypeOptions = [
  { value: 'none', name: 'None' },
  { value: 'jetA1', name: 'Jet A-1' },
  { value: 'jetBJP4', name: 'Jet B/JP-4' },
  { value: 'avgas100LL', name: 'Avgas 100 LL' },
  { value: 'mogas', name: 'Mogas' },
  { value: 'biokeroseneF56', name: 'BiokeroseneF56' },
];

export const countriesOptions = countries.map((country) => ({
  name: country.name,
  value: country.abbreviation,
}));

export const timezoneOptions = timezones.map((timezone) => ({
  name: timezone.text,
  value: timezone.value,
}));

export const faaCategoryToName = {
  'Part 121': 'Airline',
  'Part 135': 'Charter/Freight',
  'Part 91': 'General Aviation',
  GOV: 'Government',
  GND: 'Ground Vehicles',
  MED: 'Medical',
};

export const faaCategoryAlias = Object.entries(faaCategoryToName).map(([code, alias]) => ({
  code,
  alias,
}));

export const faaCategoryOptions = [
  { name: faaCategoryToName['Part 121'], value: 'Part 121' },
  { name: faaCategoryToName['Part 135'], value: 'Part 135' },
  { name: faaCategoryToName['Part 91'], value: 'Part 91' },
  { name: faaCategoryToName['GOV'], value: 'GOV' },
  { name: faaCategoryToName['GND'], value: 'GND' },
  { name: faaCategoryToName['MED'], value: 'MED' },
];

export const iconTypeOptions = [
  { name: 'SEL', value: 'SEL' },
  { name: 'SET', value: 'SET' },
  { name: 'MEL', value: 'MEL' },
  { name: 'MET', value: 'MET' },
  { name: 'BUZ', value: 'BUZ' },
  { name: 'JET 2', value: 'JET2' },
  { name: 'JET NB', value: 'JETNB' },
  { name: 'JET 4', value: 'JET4' },
  { name: 'JET WB', value: 'JETWB' },
  { name: 'HEL', value: 'HEL' },
  { name: 'MIL', value: 'MIL' },
  { name: 'MIL H', value: 'MILH' },
  { name: 'LSA', value: 'LSA' },
  { name: 'GLD', value: 'GLD' },
  { name: 'UAV', value: 'UAV' },
  { name: 'BLIMP', value: 'BLIMP' },
  { name: 'BLN', value: 'BLN' },
  { name: 'GND EMC', value: 'GNDEMC' },
  { name: 'GND A', value: 'GNDA' },
  { name: 'BEACON', value: 'BEACON' },
  { name: 'STAR', value: 'STAR' },
  { name: 'UFO', value: 'UFO' },
  { name: 'DIR', value: 'DIR' },
  { name: 'UKN', value: 'UKN' },
];

export const aircraftDesignGroupOptions = [
  { name: 'UKN', value: 'UKN' },
  { name: 'A1', value: 'A1' },
  { name: 'A2', value: 'A2' },
  { name: 'A3', value: 'A3' },
  { name: 'A4', value: 'A4' },
  { name: 'A5', value: 'A5' },
  { name: 'A6', value: 'A6' },
  { name: 'B1', value: 'B1' },
  { name: 'B2', value: 'B2' },
  { name: 'B3', value: 'B3' },
  { name: 'B4', value: 'B4' },
  { name: 'B5', value: 'B5' },
  { name: 'B6', value: 'B6' },
  { name: 'C1', value: 'C1' },
  { name: 'C2', value: 'C2' },
  { name: 'C3', value: 'C3' },
  { name: 'C4', value: 'C4' },
  { name: 'C5', value: 'C5' },
  { name: 'C6', value: 'C6' },
  { name: 'D1', value: 'D1' },
  { name: 'D2', value: 'D2' },
  { name: 'D3', value: 'D3' },
  { name: 'D4', value: 'D4' },
  { name: 'D5', value: 'D5' },
  { name: 'D6', value: 'D6' },
  { name: 'E1', value: 'E1' },
  { name: 'E2', value: 'E2' },
  { name: 'E3', value: 'E3' },
  { name: 'E4', value: 'E4' },
  { name: 'E5', value: 'E5' },
  { name: 'E6', value: 'E6' },
  { name: 'HEL', value: 'HEL' },
];
