import React, { useEffect, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './NewsTicker.css';

const NewsTicker = ({ text, onToggleVisibility }) => {
  const animatedRef = useRef();
  const [isVisible, setIsVisible] = useState(true);
  const [prevText, setPrevText] = useState('');
  const [animationClass, setAnimationClass] = useState('ticker__content--fade-in');

  const restartAnimation = useCallback(() => {
    if (!animatedRef.current) return;
    animatedRef.current.classList.remove('ticker__content--fade-in', 'ticker__content--fade-out');
    animatedRef.current.classList.add(animationClass);
  }, [animationClass]);

  useEffect(() => {
    let timeoutId;
    if (text !== prevText) {
      setAnimationClass('ticker__content--fade-out');
      timeoutId = setTimeout(() => {
        setPrevText(text);
        setAnimationClass('ticker__content--fade-in');
      }, 1000); // Adjust the duration to match your fade-out animation
    } else {
      restartAnimation();
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [text, prevText, restartAnimation]);

  const handleClick = () => {
    setIsVisible(!isVisible);
    onToggleVisibility();
  };

  return (
    <div className="ticker__wrapper" onClick={handleClick}>
      <div className="ticker">
        <div
          className={`ticker__content ${!isVisible ? 'ticker__content--hidden' : ''} ${animationClass}`}
          ref={animatedRef}
          title="Click to delete news."
        >
          {prevText}
        </div>
      </div>
    </div>
  );
};

NewsTicker.propTypes = {
  text: PropTypes.string,
  onToggleVisibility: PropTypes.func.isRequired,
};

export default NewsTicker;
