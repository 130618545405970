import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAirportTimeFormatter, getAirportDateFormatter, getAirportDateTimeFormatter } from 'utils/dateTimeHelpers';

export const useLocalDateFormatter = () => {
  const { timezone } = useSelector((state) => state.auth);

  const dateFormatter = useMemo(() => {
    return getAirportDateFormatter(timezone);
  }, [timezone]);

  return dateFormatter;
};

export const useLocalTimeFormatter = ({ seconds = false } = {}) => {
  const { timezone } = useSelector((state) => state.auth);

  const timeFormatter = useMemo(() => {
    return getAirportTimeFormatter(timezone, seconds);
  }, [timezone, seconds]);

  return timeFormatter;
};

export const useLocalDateTimeFormatter = ({ seconds = false } = {}) => {
  const { timezone } = useSelector((state) => state.auth);

  const timeFormatter = useMemo(() => {
    return getAirportDateTimeFormatter(timezone, seconds);
  }, [timezone, seconds]);

  return timeFormatter;
};

export const useDateFormatter = () => {
  const dateFormatter = useMemo(() => {
    return getAirportDateFormatter();
  }, []);

  return dateFormatter;
};

export const useTimeFormatter = () => {
  const timeFormatter = useMemo(() => {
    return getAirportTimeFormatter();
  }, []);

  return timeFormatter;
};

export const useDateTimeFormatter = () => {
  const dateTimeFormatter = useMemo(() => {
    return getAirportDateTimeFormatter();
  }, []);

  return dateTimeFormatter;
};
