import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './AccordionTab.css';

const AccordionTab = ({ label, name, children, className, setOpened, opened = false }) => {
  return (
    <div className={clsx('accordion-tab', className, { 'accordion-tab_opened': opened })}>
      <input
        type="checkbox"
        className="accordion-tab__input"
        id={name}
        checked={opened}
        onChange={(e) => setOpened(e.currentTarget.checked)}
      />
      <label className="accordion-tab__label" htmlFor={name}>
        {label}
      </label>
      <div className="accordion-tab__content">{children}</div>
    </div>
  );
};

AccordionTab.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  opened: PropTypes.bool,
  setOpened: PropTypes.func,
};

export default AccordionTab;
