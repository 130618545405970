import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CustomSelect from 'components/base/CustomSelect';
import Button from 'components/base/Button';
import CustomInput from 'components/base/CustomInput';
import { batchUpdateAircraftsCategory } from 'redux/modules/aircrafts';
import { faaCategoryOptions, aircraftDesignGroupOptions, iconTypeOptions } from 'utils/formFieldsHelpers';
import { handleToasts } from 'utils/handleThunkRequest';

const FieldsTitle = {
  type: 'Type',
  iconType: 'Category Icon',
  faaCategory: 'FAA Ops Category',
  aircraftDesignGroup: 'FAA AAC/ADG',
  operatorDetails: 'Operator Details',
};

const TableBatchEdit = ({ rows }) => {
  const dispatch = useDispatch();
  const [type, setType] = useState('');
  const [iconType, setIconType] = useState(iconTypeOptions[0].value);
  const [faaCategory, setFaaCategory] = useState(faaCategoryOptions[0].value);
  const [aircraftDesignGroup, setAircraftDesignGroup] = useState(aircraftDesignGroupOptions[0].value);
  const [operatorDetails, setOperatorDetails] = useState('');
  const { fetching } = useSelector((state) => state.aircrafts);

  const handleBatchUpdate = (fieldName, value) => {
    dispatch(
      batchUpdateAircraftsCategory({
        idList: rows.map((item) => item.original['id']),
        [fieldName]: value,
      })
    ).then(
      handleToasts({
        successMessage: `Selected items ${FieldsTitle[fieldName]} successfully updated`,
      })
    );
  };

  return (
    <div className="table-batch">
      <div className="table-batch__item">
        <CustomInput
          value={type}
          onChange={(e) => {
            setType(e?.currentTarget?.value || '');
          }}
        />
        <Button
          className="table-batch__button"
          mods={{ action: true, isSubmitting: fetching }}
          disabled={rows.length === 0 || fetching}
          type="submit"
          onClick={() => handleBatchUpdate('type', type)}
        >
          Update {FieldsTitle['type']}
        </Button>
      </div>
      <div className="table-batch__item">
        <CustomSelect options={iconTypeOptions} value={iconType} onChange={setIconType} />
        <Button
          className="table-batch__button"
          mods={{ action: true, isSubmitting: fetching }}
          disabled={rows.length === 0 || fetching}
          type="submit"
          onClick={() => handleBatchUpdate('iconType', iconType)}
        >
          Update {FieldsTitle['iconType']}
        </Button>
      </div>
      <div className="table-batch__item">
        <CustomSelect options={faaCategoryOptions} value={faaCategory} onChange={setFaaCategory} />
        <Button
          className="table-batch__button"
          mods={{ action: true, isSubmitting: fetching }}
          disabled={rows.length === 0 || fetching}
          type="submit"
          onClick={() => handleBatchUpdate('faaCategory', faaCategory)}
        >
          Update {FieldsTitle['faaCategory']}
        </Button>
      </div>
      <div className="table-batch__item">
        <CustomSelect
          options={aircraftDesignGroupOptions}
          value={aircraftDesignGroup}
          onChange={setAircraftDesignGroup}
        />
        <Button
          className="table-batch__button"
          mods={{ action: true, isSubmitting: fetching }}
          disabled={rows.length === 0 || fetching}
          type="submit"
          onClick={() => handleBatchUpdate('aircraftDesignGroup', aircraftDesignGroup)}
        >
          Update {FieldsTitle['aircraftDesignGroup']}
        </Button>
      </div>
      <div className="table-batch__item">
        <CustomInput
          value={operatorDetails}
          onChange={(e) => {
            setOperatorDetails(e?.currentTarget?.value || '');
          }}
        />
        <Button
          className="table-batch__button"
          mods={{ action: true, isSubmitting: fetching }}
          disabled={rows.length === 0 || fetching}
          type="submit"
          onClick={() => handleBatchUpdate('operatorDetails', operatorDetails)}
        >
          Update {FieldsTitle['operatorDetails']}
        </Button>
      </div>
    </div>
  );
};

TableBatchEdit.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default TableBatchEdit;
