import React, { lazy } from 'react';
import Page from 'components/common/Page';
const Events = lazy(() => import('./Events'));

const EventsPage = () => {
  return (
    <Page>
      <Events />
    </Page>
  );
};

export default EventsPage;
