import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import '../Header.css';

const Item = ({ name, content, className, icon = null, onClick = undefined }) => {
  return onClick ? (
    <button type="button" className={clsx('header-item', className)} onClick={onClick}>
      {icon && <img src={icon} alt="header-item-icon" className="header-item__icon" />}
      <div className="header-item__inner">
        <div className="header-item__top">{name}</div>
        <div className="header-item__bottom">{content}</div>
      </div>
    </button>
  ) : (
    <div className={clsx('header-item', className)}>
      {icon && <img src={icon} alt="header-item-icon" className="header-item__icon" />}
      <div className="header-item__inner">
        <div className="header-item__top">{name}</div>
        <div className="header-item__bottom">{content}</div>
      </div>
    </div>
  );
};

Item.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.node,
  icon: PropTypes.string,
  onClick: PropTypes.func,
};

export default Item;
