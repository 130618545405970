import React, { lazy } from 'react';
import Page from 'components/common/Page';
const CustomersEdit = lazy(() => import('./CustomersEdit'));

const CustomersEditPage = () => {
  return (
    <Page>
      <CustomersEdit />
    </Page>
  );
};

export default CustomersEditPage;
