import React, { lazy } from 'react';
import Page from 'components/common/Page';
const Aircrafts = lazy(() => import('./Aircrafts'));

const AircraftsPage = () => {
  return (
    <Page>
      <Aircrafts />
    </Page>
  );
};

export default AircraftsPage;
