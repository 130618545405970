import React, { lazy } from 'react';
import Page from 'components/common/Page';
const Statistics = lazy(() => import('./Statistics'));

const StatisticsPage = () => {
  return (
    <Page>
      <Statistics />
    </Page>
  );
};

export default StatisticsPage;
