import PropTypes from 'prop-types';

export const GeofencePropType = PropTypes.exact({
  id: PropTypes.number,
  active: PropTypes.bool,
  name: PropTypes.string,
  remarks: PropTypes.string,
  lat1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lon1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  remark1: PropTypes.string,
  lat2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lon2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  remark2: PropTypes.string,
  lat3: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lon3: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  remark3: PropTypes.string,
  lat4: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lon4: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  remark4: PropTypes.string,
  lowerAlt: PropTypes.number,
  upperAlt: PropTypes.number,
  isGround: PropTypes.bool,
  isAirport: PropTypes.bool,
  airport: PropTypes.shape({
    id: PropTypes.number,
    icao: PropTypes.string,
  }),
  airportId: PropTypes.number,
  airportICAO: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
});

export const CustomerPropType = PropTypes.exact({
  gatFuel: PropTypes.string,
  gatFlights: PropTypes.string,
  gatServices: PropTypes.string,
  gatImageUrl: PropTypes.string,
  id: PropTypes.number,
  enabled: PropTypes.bool,
  customer_name: PropTypes.string,
  street_address: PropTypes.string,
  postal_code: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  icao: PropTypes.string,
  iata: PropTypes.string,
  airport_name: PropTypes.string,
  faa: PropTypes.string,
  airport_ownership: PropTypes.string,
  website: PropTypes.string,
  lat: PropTypes.number,
  lon: PropTypes.number,
  elevation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  category: PropTypes.string,
  zoomName1: PropTypes.string,
  zoomDiameter1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  zoomName2: PropTypes.string,
  zoomDiameter2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  zoomName3: PropTypes.string,
  zoomDiameter3: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  zoomName4: PropTypes.string,
  zoomDiameter4: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  time_zone: PropTypes.string,
  language: PropTypes.string,
  runway1a: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orientation1a: PropTypes.string,
  runway1b: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orientation1b: PropTypes.string,
  runway2a: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orientation2a: PropTypes.string,
  runway2b: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orientation2b: PropTypes.string,
  runway3a: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orientation3a: PropTypes.string,
  runway3b: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orientation3b: PropTypes.string,
  runway4a: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orientation4a: PropTypes.string,
  runway4b: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orientation4b: PropTypes.string,
  runway5a: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orientation5a: PropTypes.string,
  runway5b: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orientation5b: PropTypes.string,
  runway6a: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orientation6a: PropTypes.string,
  runway6b: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orientation6b: PropTypes.string,
  fuelType1: PropTypes.string,
  fuelType2: PropTypes.string,
  fuelType3: PropTypes.string,
  fuelType4: PropTypes.string,
  fuelSelfService: PropTypes.bool,
  twr_ctaf: PropTypes.string,
  twr_ctaf_checkbox: PropTypes.bool,
  gnd: PropTypes.string,
  gnd_checkbox: PropTypes.bool,
  atis: PropTypes.string,
  atis_checkbox: PropTypes.bool,
  fromHours: PropTypes.string,
  toHours: PropTypes.string,
  daysPerWeek: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ppr: PropTypes.bool,
  startDate: PropTypes.string,
  serviceTerm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paymentCode: PropTypes.string,
  paymentTerm: PropTypes.string,
  serviceLevelAgreement: PropTypes.string,
  remarks: PropTypes.string,
  detectionZone: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  detectionZoneHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasFeatureNoiseAbatement: PropTypes.bool,
  hasFeatureScheduledFlights: PropTypes.bool,
  metarIcao: PropTypes.string,
  updatedAt: PropTypes.string,
  createdAt: PropTypes.string,
  usersCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  operationsMetarIcao: PropTypes.string,
  customerGroupString: PropTypes.string,
});

export const MeasureUnitsPropType = PropTypes.shape({
  altitude: PropTypes.string,
  airPressure: PropTypes.string,
  runwayLength: PropTypes.string,
  visibility: PropTypes.string,
  speed: PropTypes.string,
  temperature: PropTypes.string,
  mass: PropTypes.string,
  verticalSpeed: PropTypes.string,
});

export const BasedAircraftPropType = PropTypes.shape({
  airportICAO: PropTypes.string,
  daysSeenCount: PropTypes.number,
  hangarInsurance: PropTypes.bool,
  iconType: PropTypes.string,
  lastUpdateDate: PropTypes.string,
  parkingPosition: PropTypes.string,
  registration: PropTypes.string,
  type: PropTypes.string,
});

export const AircraftInfoPropType = PropTypes.shape({
  hex: PropTypes.string, // hex ICAO Mode-S or Flarm ID, in Hex format. Uppercase for Mode-S and Flarm, lowercase for TIS-B
  lat: PropTypes.number, // lat Flight data: Latitude (-90 to 90º)
  lon: PropTypes.number, // lon Flight data: Longitude (-180 to 180º)
  trk: PropTypes.number, // trk Flight data: True track (-180 to 180º)
  alt: PropTypes.number, // alt Flight data: Barometric uncalibrated altitude (ft)
  altg: PropTypes.number, // altg GNSS ellipsoidal altitude (ft)	Either directly reported or from baro+difference
  spd: PropTypes.number, // spd Flight data: Ground Speed (kt)
  vrt: PropTypes.number, // vrt Flight data: Vertical Rate (ft/min)
  cat: PropTypes.string, // cat Aircraft category		As reported in ADS-B or FLARM. See table below.
  typ: PropTypes.string, // typ Type designator database
  reg: PropTypes.string, // reg Registration from open database or if not available automatically deduced for some countries with known mapping (FR, BE, PT, CH, US and CA)
  ava: PropTypes.string, // ava Flight data: available sources. One letter per available source. A=ADSB, M=proxy MLAT, F=FlightAware MLAT, O=OGN, L=FLARM
  src: PropTypes.string, // src Source	The current preferred/selected source (A|M|F|O|L). See ava
  gda: PropTypes.string, // gda Airborne/Ground (A|G|a|g) G for on ground, A for airborne (if TIS-B then lowercase g or a)
  uti: PropTypes.number, // uti Time of last message: Seconds since UNIX epoch
  ns: PropTypes.number, // ns Time of last message: Nanoseconds since Second epoch
  cla: PropTypes.number, // cla
  fli: PropTypes.string, // fli Flight Identification. From ADS-B or BDS2,0 as specified on ICAO Annex 10, Volume IV, Table 3-9; "?" when A/C reports loss of interface
  tmp: PropTypes.number, // tmp Temperature (ºC)Synthetic value calculated from several fields. Might not be always reliable
  wsp: PropTypes.number, // wsp Wind Speed (kt)	Synthetic value calculated from several fields. Might not be always reliable
  wdi: PropTypes.number, // wdi Wind from Direction (0-360º)	0	Synthetic value calculated from several fields. Might not be always reliable
  from: PropTypes.string, // from Origin		Origin airport from Flightroutes database
  to: PropTypes.string, // to	Destination		Destination airport from Flightroutes database
  opr: PropTypes.string, // opr Operator		Operator from open database
  dis: PropTypes.number, // dis Distance (km or NM depending on configuration)		To the Receiver GNSS location
  dbm: PropTypes.number, // dbm Approximate signal level in dBm
  cou: PropTypes.string, // cou Country		According to Mode-S allocation block
  squ: PropTypes.string, // squ Mode-A (Squawk)		While this is a Mode-A code, it can only be correlated in Mode-S targets. Mode-A or Mode-AC targets are not displayed in decoded formats
  tru: PropTypes.number, // tru Trust count		Number of Mode-S frames successfully decoded from this A/C. Statistically it's possible that for a very low count the A/C does not actually exist
  lla: PropTypes.number, // lla Age of last Position (s)
  mop: PropTypes.number, // mop ADS-B MOPS version		null=no ADS-B frames received, 0=default for unconfirmed version, 1 or 2 for corresponding confirmed version
  alr: PropTypes.number, // alr Alert status		0=no alert, 1=temporary (Squawk changed previous 18s), 2=mode-s alert, 3=permanent (Squawk 7500,7600,7700). Since Mode-S DF4,5,20,21 do not distinguish between temporary and permanent alert, the value 2 is used to mean either 1 or 3 and happens for non ADS-B A/C targets
  spi: PropTypes.bool, // spi SPI (Squawk ident)
  tcm: PropTypes.number, // tcm ACAS/TCAS status	0, alert only 2	0=not operational, 1=operational, 2=alert
  ape: PropTypes.bool, // ape Autopilot engaged
  pic: PropTypes.number, // pic Asterix PIC. EUROCONTROL-SPEC-0149-12 (ASTERIX Cat 021 Ed 2.4)
  nacp: PropTypes.number, // nacp ADS-B NACp
  sil: PropTypes.number, // sil ADS-B SIL
  sda: PropTypes.number, // sda ADS-B SDA
  qnhs: PropTypes.number, // qnhs Selected QNH. Actual value entered by the A/C crew
  alts: PropTypes.number, // alts Selected Altitude.	Actual value entered by the A/C crew
  hdgs: PropTypes.number, // hdgs Selected Heading. Actual value entered by the A/C crew
  pest: PropTypes.number, // pest
  nocl: PropTypes.number, // nocl
  clnt: PropTypes.arrayOf(PropTypes.string), // clnt list of visible to plain stations
  faa: PropTypes.string, // faa faaCategory of the aircraft
  ict: PropTypes.string, // ict iconType defined by user
  off: PropTypes.bool, // off shows if aircraft has turned off ads-b
  own: PropTypes.string, // own owner details
  img: PropTypes.string, // img path to image of aircraft
  bsd: PropTypes.arrayOf(PropTypes.string), // bsd airport icao where aircraft is based
});

export const NoiseAbatementFlightPropType = PropTypes.shape({
  reg: PropTypes.string.isRequired,
  fli: PropTypes.string,
  squ: PropTypes.string,
  src: PropTypes.string,
  cla: PropTypes.number,
  typ: PropTypes.string,
  fpth: PropTypes.arrayOf(
    PropTypes.shape({
      altg: PropTypes.number,
      lon: PropTypes.number,
      alt: PropTypes.number,
      spd: PropTypes.number,
      uti: PropTypes.number,
      vrt: PropTypes.number,
      lat: PropTypes.number,
    })
  ),
});

export const NoiseAbatementPropType = PropTypes.shape({
  markerLocation: PropTypes.exact({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  markerText: PropTypes.string,
  isSimplifiedNoise: PropTypes.bool,
  isNoiseFetching: PropTypes.bool,
  simplifiedData: PropTypes.arrayOf(NoiseAbatementFlightPropType),
  data: PropTypes.arrayOf(NoiseAbatementFlightPropType),
  chosenNoiseFlight: PropTypes.shape({
    reg: PropTypes.string.isRequired,
    fli: PropTypes.string,
    squ: PropTypes.string,
    src: PropTypes.string,
    cla: PropTypes.number,
    typ: PropTypes.string,
    fpth: PropTypes.arrayOf(
      PropTypes.shape({
        altg: PropTypes.number,
        lon: PropTypes.number,
        alt: PropTypes.number,
        spd: PropTypes.number,
        uti: PropTypes.number,
        vrt: PropTypes.number,
        lat: PropTypes.number,
      })
    ),
  }),
  setChosenNoiseFlight: PropTypes.func,
  flightTagData: PropTypes.shape({
    speed: PropTypes.number,
    altitude: PropTypes.number,
    registration: PropTypes.string,
    vSpeed: PropTypes.number,
    callsign: PropTypes.string,
    squawk: PropTypes.string,
    type: PropTypes.string,
    position: PropTypes.shape({
      lon: PropTypes.number,
      lat: PropTypes.number,
    }),
    uti: PropTypes.number,
  }),
  setFlightTagData: PropTypes.func,
});
