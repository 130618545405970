import React, { lazy } from 'react';
import Page from 'components/common/Page';
const CustomersSettingsEdit = lazy(() => import('./CustomersSettingsEdit'));

const CustomersSettingsEditPage = () => {
  return (
    <Page>
      <CustomersSettingsEdit />
    </Page>
  );
};

export default CustomersSettingsEditPage;
