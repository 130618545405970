import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './MenuButton.css';

const MenuButton = ({ onClick, className, isCloseButton }) => {
  return (
    <button
      type="button"
      className={clsx('menu-button', className, { 'menu-button_close': isCloseButton })}
      onClick={onClick}
    >
      <span className="menu-button__stick" />
      <span className="menu-button__stick" />
      <span className="menu-button__stick" />
    </button>
  );
};

MenuButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  isCloseButton: PropTypes.bool,
};

export default MenuButton;
