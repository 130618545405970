/**
 * @typedef {Object} NOAADetails
 * @property {string} legendUrl - URL to legend img
 * @property {string[]} timestampRange - Array of ISO Timestamps indicating available radar views
 */

/**
 * Returns some details about NOAA WMS service
 * @param {string} xml xml document with capabillities of the noaa WMS service
 * @returns {NOAADetails}
 */
export function getNOAACapabillitiesDetails(xml) {
  const domParser = new DOMParser();
  const xmlDom = domParser.parseFromString(xml, 'application/xml');

  const dimensionList = xmlDom.querySelector('Extent')?.innerHTML?.split(',');
  const timestampRange = [
    dimensionList[0],
    dimensionList[Math.round(dimensionList.length / 2)],
    dimensionList[dimensionList.length - 1],
  ];

  const legendUrlElement = xmlDom.querySelector('LegendURL OnlineResource');
  const legendUrl = legendUrlElement.attributes['xlink:href'].textContent;

  return {
    timestampRange,
    legendUrl,
  };
}
