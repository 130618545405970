import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filters: {},
  sort: {},
  page: {},
};

const parseSortState = (value) => {
  if (!value) return undefined;
  return value === 'desc';
};

export const tablesSlice = createSlice({
  name: 'tables',
  initialState,
  reducers: {
    setFilter(state, action) {
      const {
        payload: { column, tableName, value },
      } = action;
      if (!state.filters[tableName]) state.filters[tableName] = {};
      state.filters[tableName][column] = value;
    },
    setSort(state, action) {
      const {
        payload: { column, tableName, value },
      } = action;
      state.sort[tableName] = {
        id: column,
        desc: parseSortState(value),
      };
    },
    setPage(state, action) {
      const {
        payload: { tableName, value },
      } = action;
      state.page[tableName] = value;
    },
    resetTable(state, action) {
      const tableName = action.payload;

      state.page[tableName] = 0;
      state.filters[tableName] = {};
      state.sort[tableName] = {};
    },
    resetPagination(state) {
      state.page = {};
    },
  },
});

export default tablesSlice.reducer;

export const { setFilter, setSort, setPage, resetTable, resetPagination } = tablesSlice.actions;
