import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import CustomInput from './index';

const FormikInput = ({ name, onChangeMiddleware, onBlurMiddleware, clearErrorsOnChange = false, ...props }) => {
  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors, ...rest }, // also formik values, setXXXX, handleXXXX, dirty, isValid, status, etc
      }) => {
        return (
          <CustomInput
            id={name}
            error={touched[field.name] && errors[field.name]}
            {...field}
            onChange={(e) => {
              if (onChangeMiddleware) onChangeMiddleware(e);
              field.onChange(e);
              if (clearErrorsOnChange) {
                rest?.setErrors?.({});
              }
            }}
            onBlur={(e) => {
              if (onBlurMiddleware) onBlurMiddleware(e);
              field.onBlur(e);
            }}
            {...props}
          />
        );
      }}
    </Field>
  );
};

FormikInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  textarea: PropTypes.bool,
  select: PropTypes.bool,
  measureUnit: PropTypes.string,
  onFocus: PropTypes.func,
  type: PropTypes.oneOf(['text', 'password', 'email', 'image', 'number', 'date', 'time']),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  step: PropTypes.number,
  disabled: PropTypes.bool,
  onChangeMiddleware: PropTypes.func,
  onBlurMiddleware: PropTypes.func,
  login: PropTypes.bool,
  readonly: PropTypes.bool,
  pattern: PropTypes.string,
  clearErrorsOnChange: PropTypes.bool,
};

export default FormikInput;
