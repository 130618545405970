import { useEffect, useState, useReducer } from 'react';
import axios from 'axios';
import { getNOAACapabillitiesDetails } from 'utils/noaa.utils';

const WMS_RADAR_LAYER_INTERVAL = 1500;

export function useWeatherRadarPlayer(wmsUrl) {
  const [forceUpdatevalue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [currentTimestampIndex, setCurrentTimestampIndex] = useState(null);
  const [noaaCapabillities, setNoaaCapabillities] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const { legendUrl, timestampRange } = noaaCapabillities || {};

  useEffect(() => {
    if (wmsUrl) {
      axios
        .get(wmsUrl, {
          params: {
            service: 'wms',
            version: '1.1.1',
            request: 'GetCapabilities',
          },
        })
        .then((response) => {
          const capabillitites = getNOAACapabillitiesDetails(response.data);

          setNoaaCapabillities(capabillitites);
          setCurrentTimestampIndex(capabillitites.timestampRange.length - 1);
        });
    } else {
      setNoaaCapabillities(null);
      setCurrentTimestampIndex(null);
    }
  }, [wmsUrl, forceUpdatevalue]);

  useEffect(() => {
    let intervalId;

    if (isPlaying) {
      intervalId = setTimeout(() => {
        const isLastTimestamp = currentTimestampIndex === timestampRange?.length - 1;
        const newIndex = isLastTimestamp ? 0 : currentTimestampIndex + 1;
        setCurrentTimestampIndex(newIndex);
      }, WMS_RADAR_LAYER_INTERVAL);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isPlaying, timestampRange, currentTimestampIndex]);

  const togglePlaying = () => {
    setIsPlaying(!isPlaying);
  };

  return {
    legendUrl,
    currentTimestampIndex,
    timestampRange,
    isPlaying,
    togglePlaying,
    forceUpdate,
  };
}
