import React, { lazy } from 'react';
import Page from 'components/common/Page';
const Dashboard = lazy(() => import('./Dashboard'));

const DashboardPage = () => {
  return (
    // TODO: enable bottom bar when graph is ready
    <Page fullscreen>
      <Dashboard />
    </Page>
  );
};

export default DashboardPage;
