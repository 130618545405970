import io from 'socket.io-client';

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || process.env.REACT_APP_API_URL || '';

export class SocketService {
  constructor() {
    this.socket = io(SOCKET_URL, {
      transports: ['websocket'],
    });
  }

  alarmChecked(operationId, token) {
    this.socket.emit('alarmChecked', { operationId, token });
  }

  // generic
  subscribeTopic(topicName, token, params = {}) {
    this.socket.emit(`subscribe_${topicName}`, { token, ...params });
    const resubscribeHandler = () => {
      this.socket.emit(`subscribe_${topicName}`, { isResubscribe: true, token, ...params });
    };
    this.socket.on('connect', resubscribeHandler);

    return () => {
      this.socket.emit(`unsubscribe_${topicName}`, { token, ...params });
      this.socket.removeListener('connect', resubscribeHandler);
    };
  }

  onTopic(topicName, cb) {
    this.socket.on(topicName, cb);

    return () => {
      this.socket.removeListener(topicName, cb);
    };
  }

  onSyncProgress(cb) {
    return this.socket.on('syncProgress', cb);
  }
}
