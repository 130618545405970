import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

const initialState = {
  users: [],
  fetching: true,
  dataFetched: false,
};

export const fetchUsers = createAsyncThunk('users/fetchAll', async () => {
  return api.getUsers();
});

export const fetchUserById = createAsyncThunk('users/fetchById', async (id) => {
  return api.getUserById(id);
});

export const createUser = createAsyncThunk('users/create', async (user) => {
  return api.createUser(user);
});

export const updateUser = createAsyncThunk('users/update', async (user) => {
  return api.updateUser(user);
});

export const deleteUser = createAsyncThunk('users/delete', async (id) => {
  return api.deleteUser(id);
});

const handlePendingAction = (state) => {
  state.fetching = true;
};

const handleRejectedAction = (state) => {
  state.fetching = false;
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  extraReducers: {
    [fetchUsers.pending]: handlePendingAction,
    [fetchUserById.pending]: handlePendingAction,

    [fetchUsers.fulfilled]: (state, action) => {
      state.users = action.payload.map((user) => {
        const { password, airport, ...restOfUser } = user;
        return { ...restOfUser, icao: airport?.icao };
      });
      state.fetching = false;
    },
    [fetchUserById.fulfilled]: (state, action) => {
      if (!state.users.find((user) => user.id === action.payload.id)) {
        const { password, airport, ...restOfUser } = action.payload;
        const modifiedUser = { ...restOfUser, icao: airport?.icao };
        state.users = [...state.users, modifiedUser];
        state.fetching = false;
      }
    },
    [createUser.fulfilled]: (state, action) => {
      const { password, airport, ...restOfUser } = action.payload;
      const modifiedUser = { ...restOfUser, icao: airport?.icao };
      state.users.push(modifiedUser);
    },
    [updateUser.fulfilled]: (state, action) => {
      const user = state.users.find((u) => u.id === action.payload.id);
      if (user) {
        const { password, ...modifiedUser } = action.payload;
        Object.assign(user, modifiedUser);
      }
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.users = state.users.filter((u) => {
        return u.id !== +action.payload.id;
      });
    },
    [fetchUsers.rejected]: handleRejectedAction,
    [fetchUserById.rejected]: handleRejectedAction,
  },
  reducers: {
    setDataFetched(state) {
      state.dataFetched = true;
    },
  },
});

export default usersSlice.reducer;
export const { setDataFetched } = usersSlice.actions;
