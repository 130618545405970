import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './Checkbox.css';

const Checkbox = ({
  type = 'checkbox',
  name,
  onChange,
  onBlur,
  onFocus,
  onClick,
  id,
  value,
  checked,
  className,
  classNameInput,
  disabled,
  title,
}) => {
  return (
    <div className={clsx('checkbox__wrap', className)}>
      <input
        className={clsx(classNameInput, 'checkbox__input')}
        type={type}
        name={name}
        id={id}
        value={value}
        checked={checked}
        onClick={onClick}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
      />
      <div className={clsx('checkbox', { checkbox_radio: type === 'radio' })} />
    </div>
  );
};

Checkbox.propTypes = {
  type: PropTypes.oneOf(['checkbox', 'radio']),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]).isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  className: PropTypes.string,
  classNameInput: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

export default Checkbox;
