import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { MAP_CONTROLS } from 'utils/globalConstants';
import { useLocalTimeFormatter } from 'hooks/useFormatters';
import { useWebsocketSubscription } from 'hooks/useWebsocketSubscription';
import { initFlights, addFlight } from 'redux/modules/notifications';
import CustomTable from 'components/common/CustomTable';
import DNDModal from 'components/base/DNDModal';
import Spinner from 'components/base/Spinner';

import './FlightsModal.css';

const StatusCell = ({ row: { original } }) => {
  const { eventName, eventColor } = original;
  return <span className={clsx('event-status-cell', `event-status-cell_${eventColor}`)}>{eventName}</span>;
};

StatusCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
    original: PropTypes.shape({
      id: PropTypes.number,
      eventName: PropTypes.string,
      eventColor: PropTypes.string,
    }),
  }),
};

const FlightsModal = ({ top = 0, left = 0, width = 400, height = 300 }) => {
  const dispatch = useDispatch();
  const [flightsData] = useWebsocketSubscription('flightsModal');
  useEffect(() => {
    if (Array.isArray(flightsData)) {
      dispatch(initFlights(flightsData));
    } else if (flightsData) {
      dispatch(addFlight(flightsData));
    }
  }, [dispatch, flightsData]);

  const { flights } = useSelector((state) => state.notifications);
  const timeFormatter = useLocalTimeFormatter();

  const flightsList = useMemo(() => {
    return flights.map(({ createdAt, registration, ...operationData }) => {
      return {
        ...operationData,
        registration,
        time: timeFormatter.format(new Date(createdAt)),
      };
    });
  }, [flights, timeFormatter]);

  const columns = useMemo(
    () => [
      {
        Header: 'Registration',
        accessor: 'registration',
        width: 100,
      },
      {
        Header: 'Time',
        accessor: 'time',
        width: 80,
        center: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 180,
        Cell: StatusCell,
        center: true,
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data: flightsList });

  return (
    <DNDModal id={MAP_CONTROLS.FLIGHTS} left={left} top={top} title="Flights" width={width} height={height}>
      <div className="flights-table__wrapper">
        {!flightsData ? (
          <Spinner className="flights-table__spinner" />
        ) : (
          <CustomTable tableName="flights" tableInstance={tableInstance} className="flights-table" />
        )}
      </div>
    </DNDModal>
  );
};

FlightsModal.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default FlightsModal;
