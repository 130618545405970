import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { MAP_CONTROLS, ACCORDION_CONTROLS } from 'utils/globalConstants';
import DNDModal from 'components/base/DNDModal';
import AccordionTab from 'components/base/AccordionTab';
import { toggleModalAccordion } from 'redux/modules/modal';
import ArchiveFeedForm from './ArchiveFeedForm';
import LiveFeedForm from './LiveFeedForm';

import './LiveATCModal.css';

const LiveATCModal = ({ top = 0, left = 0, width = 400, height }) => {
  const dispatch = useDispatch();
  const { accordions } = useSelector((state) => state.modal);

  return (
    <DNDModal id={MAP_CONTROLS.LIVE_ATC} left={left} top={top} title="ATC" width={width} height={height}>
      <div className="atc">
        <div className="dndmodal__divider" />
        <AccordionTab
          label="Live ATC"
          name={ACCORDION_CONTROLS.ATC_LIVE}
          opened={accordions[ACCORDION_CONTROLS.ATC_LIVE]}
          setOpened={(checked) => dispatch(toggleModalAccordion({ name: ACCORDION_CONTROLS.ATC_LIVE, state: checked }))}
        >
          <LiveFeedForm />
        </AccordionTab>
        <div className="dndmodal__divider" />
        <AccordionTab
          label="Records"
          name={ACCORDION_CONTROLS.ATC_ARCHIVE}
          opened={accordions[ACCORDION_CONTROLS.ATC_ARCHIVE]}
          setOpened={(checked) =>
            dispatch(toggleModalAccordion({ name: ACCORDION_CONTROLS.ATC_ARCHIVE, state: checked }))
          }
        >
          <ArchiveFeedForm />
        </AccordionTab>
      </div>
    </DNDModal>
  );
};

LiveATCModal.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default LiveATCModal;
