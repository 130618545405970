import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useAsyncDebounce } from 'react-table';
import { resetPagination } from 'redux/modules/tables';

import { ReactComponent as Search } from './searchIcon.svg';

const TableGlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((val) => {
    dispatch(resetPagination());
    setGlobalFilter(val || undefined);
  }, 200);

  return (
    <div className="container-search">
      <input
        className="input container-search__input"
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder="Search"
      />
      <Search className="container-search__icon" />
    </div>
  );
};

TableGlobalFilter.propTypes = {
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func,
};

export default TableGlobalFilter;
