import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

export const fetchExternalDBStatus = createAsyncThunk('externalDB/fetchStatus', async (params) => {
  return api.getExternalDBStatus(params);
});

/**
 * @typedef {Object} DBStatus
 * @property {string} id - Uniquer identifier
 * @property {string} source - ExternalDBName
 * @property {string} lastSyncUser - Last user who synced external db
 * @property {string} lastSyncDate - Last date when synced external db
 * @property {string} lastDbUpdate - Date external db was uploaded
 * @property {string} createdAt - Date external db was initiated
 */

/**
 * @typedef {Object} ExternalDBState
 * @property {DBStatus} FAA - Contains db status about FAA
 */
const initialState = {
  fetching: true,
  FAA: {},
  LADD: {},
};

const handlePendingAction = (state) => {
  state.fetching = true;
};

const handleRejectedAction = (state) => {
  state.fetching = false;
};

export const externalDBSlice = createSlice({
  name: 'externalDB',
  initialState,
  extraReducers: {
    [fetchExternalDBStatus.pending]: handlePendingAction,
    [fetchExternalDBStatus.fulfilled]: (state, action) => {
      const externalDBStatuses = action.payload;
      externalDBStatuses.forEach((externalDBStatus) => {
        state[externalDBStatus.source] = externalDBStatus;
      });
      state.fetching = false;
    },
    [fetchExternalDBStatus.rejected]: handleRejectedAction,
  },
  reducers: {
    updateExternalDBStatus(state, action) {
      const { payload: externalDBStatus } = action;
      state[externalDBStatus.source] = externalDBStatus;
    },
  },
});

export const { updateExternalDBStatus } = externalDBSlice.actions;

export default externalDBSlice.reducer;
