import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Polyline, Circle } from 'react-leaflet';
import { clampValue } from 'utils/mapUtils';
import { MAX_SECONDS_BETWEEN_FLIGHT_POINTS } from 'utils/globalConstants';
import { NoiseAbatementPropType } from 'utils/commonPropTypes';
import FlightTag from 'pages/NoiseAbatement/FlightTag';
import { ReactComponent as LocationIcon } from 'pages/NoiseAbatement/locationIcon.svg';
import CustomMarker from '../CustomMarker';

const Noise = ({
  noiseData: {
    markerLocation = null,
    markerText,
    isNoiseFetching,
    data,
    chosenNoiseFlight,
    setChosenNoiseFlight,
    flightTagData,
    setFlightTagData,
  },
  zoomLevel,
}) => {
  const isSimplifiedNoise = Boolean(data?.[0]?.isAsc);

  const getLineWeightFromZoom = () => {
    if (zoomLevel <= 12) return 1;
    if (zoomLevel > 12 && zoomLevel <= 14) return 3;
    if (zoomLevel > 14 && zoomLevel <= 17) return 4;
    return 7;
  };

  const getPointRadiusFromZoom = () => {
    if (zoomLevel <= 12) return 20;
    if (zoomLevel > 12 && zoomLevel <= 14) return 18;
    if (zoomLevel > 14 && zoomLevel <= 16) return 12;
    if (zoomLevel > 16 && zoomLevel <= 19) return 8;
    return 1;
  };

  const getPointWeightFromZoom = () => {
    if (zoomLevel <= 12) return 4;
    if (zoomLevel > 12 && zoomLevel <= 14) return 2;
    return 1;
  };

  const getNoiseColor = (alt = 150, timestamp = 0, timestampNext = 0) => {
    if (Math.abs(timestamp - timestampNext) > MAX_SECONDS_BETWEEN_FLIGHT_POINTS) return '#7f8a93';

    const minAlt = 150;
    const maxAlt = 1000;

    const clampedAlt = clampValue(minAlt, maxAlt, alt);

    const convertValue = (value, startB, endB) =>
      Math.floor(((endB - startB) * (value - minAlt)) / (maxAlt - minAlt) + startB);

    const redAmount = convertValue(clampedAlt, 172, 36);
    const greenAmount = convertValue(clampedAlt, 199, 193);
    const blueAmount = convertValue(clampedAlt, 23, 255);

    return `rgb(${redAmount}, ${greenAmount}, ${blueAmount})`;
  };

  const getSimplifiedNoiseColor = ({ isAsc }) => {
    if (isAsc > 0) return '#24c1ff';
    if (isAsc < 0) return '#acc717';
    return '#fdc900';
  };

  return (
    <>
      {/* Location marker for noise abatement */}
      {markerLocation && markerText && (
        <CustomMarker position={markerLocation}>
          <div className="noise_map-marker">
            <LocationIcon className="noise_map-icon" />
            <div className="noise_map-text">{markerText}</div>
          </div>
        </CustomMarker>
      )}

      {/* Chosen flight point and flight tag for noise abatement */}
      {!isSimplifiedNoise && chosenNoiseFlight && flightTagData && (
        <CustomMarker
          position={flightTagData.position}
          className="noise_flight-tag"
          onClose={() => {
            setFlightTagData(null);
          }}
        >
          <Circle
            color={getNoiseColor(flightTagData.altitude)}
            fillOpacity={1}
            center={flightTagData.position}
            radius={getPointRadiusFromZoom() + 1}
            weight={1}
            className="map-flight-active"
            onClick={() => {
              setFlightTagData(null);
            }}
          />
          <FlightTag {...flightTagData} src={chosenNoiseFlight.src} cla={chosenNoiseFlight.cla} />
        </CustomMarker>
      )}

      {/* Flight paths */}
      {data?.map((item) => {
        return (
          <Polyline
            key={`${item.utireg}`}
            positions={item.fpth}
            weight={getLineWeightFromZoom()}
            color={isSimplifiedNoise ? getSimplifiedNoiseColor(item) : 'white'}
            onClick={() => {
              if (isNoiseFetching || isSimplifiedNoise) return;
              setFlightTagData(null);
              setChosenNoiseFlight(item);
            }}
            className={clsx('map-flightpath', { 'map-flightpath_simplified': isSimplifiedNoise })}
          />
        );
      })}

      {/* Chosen flight path */}
      {!isSimplifiedNoise &&
        chosenNoiseFlight &&
        chosenNoiseFlight.fpth.map(({ alt, lon, uti, lat }, index) => {
          return (
            chosenNoiseFlight.fpth[index + 1] && (
              <Polyline
                key={`${uti}${lat}${lon}`}
                color={getNoiseColor(alt, uti, chosenNoiseFlight.fpth[index + 1].uti)}
                positions={[{ lat, lon }, chosenNoiseFlight.fpth[index + 1]]}
                weight={getLineWeightFromZoom()}
                className="map-flight_line"
              />
            )
          );
        })}

      {/* Chosen flight path points */}
      {!isSimplifiedNoise &&
        chosenNoiseFlight &&
        chosenNoiseFlight.fpth.map(({ spd, alt, lon, uti, vrt, lat }) => {
          return (
            <Circle
              key={`${uti}${lat}${lon}`}
              color={getNoiseColor(alt)}
              fillOpacity={1}
              center={{ lat, lon }}
              radius={getPointRadiusFromZoom()}
              weight={getPointWeightFromZoom()}
              className="map-flight"
              onClick={() => {
                setFlightTagData({
                  speed: spd,
                  altitude: alt,
                  registration: chosenNoiseFlight.reg,
                  vSpeed: vrt,
                  callsign: chosenNoiseFlight.fli,
                  squawk: chosenNoiseFlight.squ,
                  type: chosenNoiseFlight.typ,
                  position: {
                    lat,
                    lng: lon,
                  },
                  uti,
                });
              }}
              onMouseOver={(e) => {
                if (!flightTagData) e.target.bringToFront();
              }}
              onMouseLeave={(e) => {
                e.target.bringToBack();
              }}
            />
          );
        })}
    </>
  );
};

Noise.propTypes = {
  noiseData: NoiseAbatementPropType,
  zoomLevel: PropTypes.number,
};

export default Noise;
