import { toast } from 'react-toastify';

export const handleToasts =
  ({ successCallback, errorCallback, successMessage, errorMessage } = {}) =>
  (data) => {
    if (data.error) {
      if (data.error.message.indexOf('401') >= 0) return;
      let errorCallbackMessage = '';
      if (errorCallback) errorCallbackMessage = errorCallback(data);
      if (errorCallbackMessage !== null) toast.error(errorCallbackMessage || errorMessage || data.error.message);
      return;
    }

    if (successCallback) successCallback(data);
    if (successMessage) toast.info(successMessage);
  };

export const forcePageReload = () => {
  window.location.href = window.location.origin;
};
