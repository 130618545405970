/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import './Button.css';
import { bemMods } from 'utils/bemMods';

const Button = ({ className, children, type = 'button', mods, disabled, to, onClick }) => {
  /*
   * JSX line disabled for ESLint due to questionable rule implementation
   */
  // eslint-disable-next-line react/button-has-type
  return to ? (
    <Link
      to={to}
      className={clsx('button', className, bemMods('button', mods), { button_disabled: disabled })}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Link>
  ) : (
    <button
      type={type}
      className={clsx('button', className, bemMods('button', mods))}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  mods: PropTypes.shape({
    revert: PropTypes.bool,
    loading: PropTypes.bool,
    action: PropTypes.bool,
    accept: PropTypes.bool,
    small: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

export default Button;
