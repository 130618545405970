import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

/**
 * @typedef {Object} Sensor
 * @property {boolean} active -Active
 * @property {string} id - ID
 * @property {string} ver - Version
 * @property {string} swtyp - SW Type
 * @property {string} hwtyp - HW Type
 * @property {number} temp - Temperature
 * @property {boolean} gnss - GNSS Time Valid
 * @property {boolean} anto - GNSS Ant. Open
 * @property {boolean} ants - GNSS Ant. Short
 * @property {number} sats - UNKNOWN
 * @property {number} conn - Conn number
 * @property {number} lat - Latitude
 * @property {number} lon - Longitude
 * @property {number} alt - Altitude
 * @property {number} ac - Aircraft
 * @property {number} iBy - Bytes/sec incoming
 * @property {number} iMsg - Msgs/sec incoming
 * @property {number} iTotal - Total MB incoming
 * @property {number} iPackets - Total packets incoming
 * @property {number} qRes - qRes
 * @property {number} login - Login
 * @property {number} logout - Logout
 * @property {number} tUTC - tUTC
 * @property {number} latency_sum - Latency Sum
 * @property {number} latency_min - Latency Min
 * @property {number} latency_max - Latency Max
 * @property {number} latency_90 - Latency 20s/90%
 * @property {number[]} latency - Backet of the latencies
 */

/**
 * @typedef {Object} SensorsState
 * @property {boolean} fetching - is fetching list
 * @property {Sensor[]} list - list of sensors
 * @property {Object} meta - meta information about total count of sensors and timestamp {date, amount}
 */
const initialState = {
  fetching: true,
  list: [],
  meta: {},
};

export const fetchSensors = createAsyncThunk('sensors/fetchAll', async () => {
  return api.getSensors();
});

const handlePendingAction = (state, { meta }) => {
  state.fetching = meta?.arg?.quiet ? state.fetching : true;
};

const handleRejectedAction = (state) => {
  state.fetching = false;
};

export const sensorsSlice = createSlice({
  name: 'sensors',
  initialState,
  extraReducers: {
    [fetchSensors.pending]: handlePendingAction,

    [fetchSensors.fulfilled]: (state, action) => {
      state.fetching = false;
      if (!action.payload.response) {
        return;
      }
      state.list = action.payload.response.map((sensorInfo) => ({
        ...sensorInfo,
      }));
      state.meta = {
        date: action.payload.dat,
        amount: action.payload.response.length,
      };
      state.fetching = false;
    },

    [fetchSensors.rejected]: handleRejectedAction,
  },
});

export default sensorsSlice.reducer;
