import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

const initialState = {
  live: {
    fetching: false,
    loaded: false,
    data: [],
  },
  archive: {
    fetching: false,
    loaded: false,
  },
};

export const fetchAtcList = createAsyncThunk('liveAtc/fetchList', async (params) => {
  return api.getAtcList(params);
});
export const fetchAtcArchiveRecording = createAsyncThunk('liveAtc/fetchArchiveRecording', async (params) => {
  return api.getAtcArchiveRecording(params);
});

export const atcSlice = createSlice({
  name: 'atc',
  initialState,
  extraReducers: {
    [fetchAtcList.pending]: (state) => {
      state.live.fetching = true;
    },
    [fetchAtcArchiveRecording.pending]: (state) => {
      state.archive.fetching = true;
    },

    [fetchAtcList.fulfilled]: (state, action) => {
      state.live.fetching = false;
      state.live.loaded = true;
      state.live.data = action.payload;
    },
    [fetchAtcArchiveRecording.fulfilled]: (state, action) => {
      state.archive.fetching = false;
      state.archive.loaded = true;
      state.archive.data = action.payload;
    },

    [fetchAtcList.rejected]: (state) => {
      state.live.fetching = false;
    },
    [fetchAtcArchiveRecording.rejected]: (state) => {
      state.archive.fetching = false;
    },
  },
});

export default atcSlice.reducer;
