import React from 'react';
import PropTypes from 'prop-types';

import './TableAlarmsRemoveButton.css';

const TableAlarmsRemoveButton = ({
  row: { original },
  cell: {
    column: { onClick },
  },
}) => {
  return (
    <button
      type="button"
      className="alarms-table__remove"
      onClick={() => {
        onClick(original);
      }}
    >
      x
    </button>
  );
};

TableAlarmsRemoveButton.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
  cell: PropTypes.shape({
    column: PropTypes.shape({
      onClick: PropTypes.func,
    }),
  }),
};

export default TableAlarmsRemoveButton;
