import React, { lazy } from 'react';
import Page from 'components/common/Page';
const ReportsCustomerGroup = lazy(() => import('./ReportsCustomerGroup'));

const ReportsCustomerGroupPage = () => {
  return (
    <Page>
      <ReportsCustomerGroup />
    </Page>
  );
};

export default ReportsCustomerGroupPage;
