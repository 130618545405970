import React, { lazy } from 'react';
import Page from 'components/common/Page';
const UsersEdit = lazy(() => import('./UsersEdit'));

const UsersEditPage = () => {
  return (
    <Page>
      <UsersEdit />
    </Page>
  );
};

export default UsersEditPage;
