import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {Object} Operation
 * @property {string} id: 1
 * @property {string} category: "A3"
 * @property {string} createdAt: "2020-09-10T13:18:32.046Z"
 * @property {string} eventName: "LANDING RW27"
 * @property {string} eventColor: "red"
 * @property {string} faaCategory: "Part 121"
 * @property {string} flight: "AAY993"
 * @property {string} icao: "XXXX"
 * @property {string} operatorDetails: "Some details"
 * @property {string} registration: "N256NV"
 * @property {string} type: "A320"
 */

/**
 * @typedef {Object} NotificationState
 * @property {Operation[]} flights - Contains list of operations which indicates flight status
 * @property {Operation[]} alarmList - Contains list of operations which indicates alarms
 */
const initialState = {
  flights: [],
  alarmList: [],
};

const FLIGHTS_PERIOD = 12 * 60 * 60 * 1000;
const MAX_FLIGHTS = 80;

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    initAlarms(state, action) {
      state.alarmList = action.payload;
    },
    initFlights(state, action) {
      const flightOperations = action.payload;
      const flightsByRegistrationMap = {};

      flightOperations.forEach((flight) => {
        if (flightsByRegistrationMap[flight.registration]) return;
        flightsByRegistrationMap[flight.registration] = flight;
      });
      const sorderUniqueFlights = Object.values(flightsByRegistrationMap).sort(
        (flightA, flightB) => new Date(flightB?.createdAt).getTime() - new Date(flightA?.createdAt).getTime()
      );
      state.flights = sorderUniqueFlights.slice(0, MAX_FLIGHTS);
    },
    addFlight(state, { payload }) {
      state.flights = state.flights.filter((flight) => {
        const isNotExist = flight.registration !== payload.registration;
        const isNotObsolete = new Date().getTime() - new Date(flight.createdAt).getTime() < FLIGHTS_PERIOD;

        return isNotExist && isNotObsolete;
      });
      state.flights = [payload, ...state.flights.slice(0, MAX_FLIGHTS)];
    },
    addAlarm(state, { payload }) {
      state.alarmList.unshift(payload);
    },
    deleteAlarm(state, { payload }) {
      state.alarmList = state.alarmList.filter(({ id }) => id !== payload.id);
    },
  },
});

export default notificationSlice.reducer;

export const { initAlarms, initFlights, addFlight, addAlarm, deleteAlarm } = notificationSlice.actions;
