import React, { lazy } from 'react';
import Page from 'components/common/Page';
const ReportsBased = lazy(() => import('./ReportsBased'));

const ReportsBasedPage = () => {
  return (
    <Page>
      <ReportsBased />
    </Page>
  );
};

export default ReportsBasedPage;
