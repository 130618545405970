import React from 'react';
import Page from 'components/common/Page';

const NotFound = () => {
  return (
    <Page>
      <h1 className="page-text-placeholder">
        404 <br /> Page not found
      </h1>
    </Page>
  );
};

export default NotFound;
