import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { WMSTileLayer } from 'react-leaflet';
import { useWeatherRadarPlayer } from 'hooks/useWeatherRadarPlayer';
import { useLocalDateTimeFormatter } from 'hooks/useFormatters';

import WeatherRadarLegend from './WeatherRadarLegend';

import { weatherRadarLayersDetails } from '../../baseLayers';

import './WeatherRadar.css';

const WeatherRadar = ({ radarType }) => {
  const { type, url, layers, title } = weatherRadarLayersDetails[radarType] || {};
  const dateTimeFormatter = useLocalDateTimeFormatter();

  const { legendUrl, isPlaying, currentTimestampIndex, togglePlaying, timestampRange, forceUpdate } =
    useWeatherRadarPlayer(url);

  useEffect(() => {
    const UPDATE_RATE = 5 * 60 * 1000; // 5min
    const intervalId = setInterval(() => {
      forceUpdate();
    }, UPDATE_RATE);

    return () => {
      clearInterval(intervalId);
    };
  }, [forceUpdate]);

  const isTimestampSpecified = typeof currentTimestampIndex === 'number' && timestampRange;
  const currentDateTime = isTimestampSpecified ? new Date(timestampRange[currentTimestampIndex]) : null;
  const currentDateTimeFormatted = currentDateTime && dateTimeFormatter.format(currentDateTime);

  return (
    <>
      {/* WEATHER RADAR */}
      {timestampRange ? (
        timestampRange.map((timestamp, index) => (
          <WMSTileLayer
            url={url}
            layers={layers}
            time={timestamp}
            format="image/png"
            transparent
            uppercase
            key={timestamp}
            className="wms-radar-layer"
            opacity={currentTimestampIndex === index ? 1 : 0}
          />
        ))
      ) : (
        <WMSTileLayer url={url} layers={layers} format="image/png" transparent uppercase />
      )}
      <WeatherRadarLegend
        title={title}
        type={type}
        url={legendUrl}
        dateTime={currentDateTimeFormatted}
        onToggle={togglePlaying}
        isPlaying={isPlaying}
      />
    </>
  );
};

WeatherRadar.propTypes = {
  radarType: PropTypes.string,
};

export default WeatherRadar;
