import React, { lazy } from 'react';
import Page from 'components/common/Page';
const Reports5010 = lazy(() => import('./Reports5010'));

const Reports5010Page = () => {
  return (
    <Page>
      <Reports5010 />
    </Page>
  );
};

export default Reports5010Page;
