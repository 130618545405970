import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setZoomBounds, setViewport, setCenter } from 'redux/modules/map';
import { permissions } from 'utils/userPermissions';
import { MAP_CONTROLS } from 'utils/globalConstants';
import { getCenterOfBounds, zoomDiameterToBounds } from 'utils/mapUtils';
import LocationSearchInput from 'components/common/LocationSearch';
import DNDModal from 'components/base/DNDModal';
import Checkbox from 'components/base/Checkbox';
import FormField from 'components/base/FormField';

import './ZoomPresetsModal.css';

const MapLayersModal = ({ top = 0, left = 0, width = 300, height }) => {
  const dispatch = useDispatch();
  const { zoomBounds, viewport } = useSelector((state) => state.map);
  const { airport } = useSelector((state) => state.auth);
  const { permissionsList } = useSelector((state) => state.auth.userInfo);
  const [mapZoomPresets, setMapZoomPresets] = useState([]);
  const isAddressLookupAllowed = permissionsList.includes(permissions.OPTION_ADDRESS_LOOKUP);

  const setLocationFromSearch = useCallback(
    (latLng) => {
      if (latLng) dispatch(setCenter([latLng.lat, latLng.lng]));
    },
    [dispatch]
  );

  useEffect(() => {
    const { lat, lon } = airport;
    const presets = [
      {
        id: 1,
        name: airport.zoomName1,
        bounds: zoomDiameterToBounds(airport.zoomDiameter1, lat, lon),
      },
      {
        id: 2,
        name: airport.zoomName2,
        bounds: zoomDiameterToBounds(airport.zoomDiameter2, lat, lon),
      },
      {
        id: 3,
        name: airport.zoomName3,
        bounds: zoomDiameterToBounds(airport.zoomDiameter3, lat, lon),
      },
      {
        id: 4,
        name: airport.zoomName4,
        bounds: zoomDiameterToBounds(airport.zoomDiameter4, lat, lon),
      },
    ];
    setMapZoomPresets(presets);
  }, [airport]);

  return (
    <DNDModal
      id={MAP_CONTROLS.ZOOM_PRESET}
      left={left}
      top={top}
      title="Zoom Point Presets"
      width={width}
      height={height}
    >
      <div className="map-zoom">
        {isAddressLookupAllowed && (
          <LocationSearchInput onSelect={setLocationFromSearch} className="map-zoom__lookup" />
        )}
        {mapZoomPresets.map((zoomPreset) => {
          const { id, name, bounds } = zoomPreset;
          return (
            <FormField label={name} name={String(id)} key={id} mods={{ row: true, largeLabel: true }}>
              <Checkbox
                label={name}
                type="radio"
                name="zoom-preset"
                id={String(id)}
                value={id}
                checked={zoomBounds === bounds}
                onChange={(e) => {
                  const { checked } = e.currentTarget;
                  if (checked) {
                    dispatch(setViewport({ ...viewport, center: getCenterOfBounds(bounds) }));
                    dispatch(setZoomBounds(bounds));
                  } else dispatch(setZoomBounds(null));
                }}
              />
            </FormField>
          );
        })}
      </div>
    </DNDModal>
  );
};

MapLayersModal.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default MapLayersModal;
