import React, { lazy } from 'react';
import Page from 'components/common/Page';
const Users = lazy(() => import('./Users'));

const UsersPage = () => {
  return (
    <Page>
      <Users />
    </Page>
  );
};

export default UsersPage;
