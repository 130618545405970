import React from 'react';
import { createPortal } from 'react-dom';
import { Marker as LeafletMarker, DivIcon } from 'leaflet';
import { MapLayer, LeafletProvider, withLeaflet } from 'react-leaflet';
import clsx from 'clsx';

class CustomMarker extends MapLayer {
  createLeafletElement(props) {
    const { position, className, pmIgnore } = props;
    const icon = new DivIcon({
      iconSize: 'auto',
      className: clsx('custom-marker', className),
    });
    const el = new LeafletMarker(position, { ...this.getOptions(props), icon, pmIgnore });
    const onMarkerAdd = () => {
      this.forceUpdate();
      el.removeEventListener('add', onMarkerAdd);
    };
    el.addEventListener('add', onMarkerAdd);

    this.contextValue = { ...props.leaflet, popupContainer: el };
    return el;
  }

  updateLeafletElement(fromProps, toProps) {
    if (toProps.position !== fromProps.position) {
      this.leafletElement.setLatLng(toProps.position);
    }
    if (toProps.icon !== fromProps.icon) {
      this.leafletElement.setIcon(toProps.icon);
    }
    if (toProps.zIndexOffset !== fromProps.zIndexOffset) {
      this.leafletElement.setZIndexOffset(toProps.zIndexOffset);
    }
    if (toProps.opacity !== fromProps.opacity) {
      this.leafletElement.setOpacity(toProps.opacity);
    }
    if (toProps.draggable !== fromProps.draggable) {
      if (toProps.draggable === true) {
        this.leafletElement.dragging.enable();
      } else {
        this.leafletElement.dragging.disable();
      }
    }
  }

  render() {
    const { children } = this.props;
    // eslint-disable-next-line no-underscore-dangle
    const container = this.leafletElement._icon;

    if (!container) {
      return null;
    }

    const portal = createPortal(children, container);

    return children == null || this.contextValue == null ? null : (
      <LeafletProvider value={this.contextValue}>{portal}</LeafletProvider>
    );
  }
}

export default withLeaflet(CustomMarker);
