import React from 'react';
import PropTypes from 'prop-types';

const PageTitle = ({ title, children }) => {
  React.useEffect(() => {
    document.title = title ? `${title} - virtower` : 'virtower';
  });
  return children;
};

PageTitle.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default PageTitle;
