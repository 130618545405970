/**
 * @typedef {Object} ElementPosition
 * @property {number} top - The X Coordinate
 * @property {number} left - The Y Coordinate
 */

/**
 * Get node's x-y position on screen
 * @param {Element} node
 * @returns {ElementPosition} object of node's position
 */
export const getNodePosition = (node) => {
  const { top, left } = node.getBoundingClientRect();
  return { top, left };
};

/**
 * Compose multiple refs into one
 * @param {Object[]} refs - react refs
 * @returns {function} merged refs
 */
export const mergeRefs = (...refs) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return (inst) => {
    filteredRefs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    });
  };
};
