import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

/**
 * @typedef {Object} Geofence
 * @property {number} id
 * @property {boolean} active
 * @property {string} name
 * @property {string} remarks
 * @property {number} lat1
 * @property {number} lon1
 * @property {string} remark1
 * @property {number} lat2
 * @property {number} lon2
 * @property {string} remark2
 * @property {number} lat3
 * @property {number} lon3
 * @property {string} remark3
 * @property {number} lat4
 * @property {number} lon4
 * @property {string} remark4
 * @property {number} lowerAlt
 * @property {number} upperAlt
 */

/**
 * @typedef {Object} GeofenceState
 * @property {Geofence[]} geofences - List of geofences
 */
const initialState = {
  geofences: [],
  fetching: true,
  dataFetched: false,
};

export const fetchGeofences = createAsyncThunk('geofence/fetchAll', async (params) => {
  return api.getGeofences(params);
});

export const fetchGeofence = createAsyncThunk('geofence/fetchOne', async (geofenceId) => {
  return api.getGeofence(geofenceId);
});

export const createGeofence = createAsyncThunk('geofence/create', async (geofence) => {
  return api.createGeofence(geofence);
});

export const updateGeofence = createAsyncThunk('geofence/update', async (geofence) => {
  return api.updateGeofence(geofence);
});

export const deleteGeofence = createAsyncThunk('geofence/delete', async (geofenceId) => {
  return api.deleteGeofence(geofenceId);
});

const handlePendingAction = (state) => {
  state.fetching = true;
};

const handleRejectedAction = (state) => {
  state.fetching = false;
};

export const geofenceSlice = createSlice({
  name: 'geofence',
  initialState,
  extraReducers: {
    [fetchGeofences.pending]: handlePendingAction,
    [fetchGeofence.pending]: handlePendingAction,

    [fetchGeofences.fulfilled]: (state, action) => {
      state.geofences = action.payload;
      state.fetching = false;
    },
    [fetchGeofence.fulfilled]: (state, action) => {
      if (!state.geofences.find((g) => g.id === +action.payload.id)) {
        state.geofences = [...state.geofences, action.payload];
        state.fetching = false;
      }
    },
    [createGeofence.fulfilled]: (state, action) => {
      state.geofences.push(action.payload);
    },
    [updateGeofence.fulfilled]: (state, action) => {
      const geofence = state.geofences.find((g) => g.id === action.payload.id);
      if (geofence) Object.assign(geofence, action.payload);
    },
    [deleteGeofence.fulfilled]: (state, action) => {
      state.geofences = state.geofences.filter((g) => {
        return g.id !== +action.payload.id;
      });
    },
    [fetchGeofences.rejected]: handleRejectedAction,
    [fetchGeofence.rejected]: handleRejectedAction,
  },
  reducers: {
    setDataFetched(state) {
      state.dataFetched = true;
    },
  },
});

export default geofenceSlice.reducer;
export const { setDataFetched } = geofenceSlice.actions;
