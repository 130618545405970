import React, { lazy } from 'react';
import Page from 'components/common/Page';
const ExternalDBSync = lazy(() => import('./ExternalDBSync'));

const ExternalDBSyncPage = () => {
  return (
    <Page>
      <ExternalDBSync />
    </Page>
  );
};

export default ExternalDBSyncPage;
