import React, { lazy } from 'react';
import Page from 'components/common/Page';
const ExternalDBFaa = lazy(() => import('./ExternalDBFaa'));

const ExternalDBFaaPage = () => {
  return (
    <Page>
      <ExternalDBFaa />
    </Page>
  );
};

export default ExternalDBFaaPage;
