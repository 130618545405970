import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { fetchAtcArchiveRecording } from 'redux/modules/atc';
import FormField from 'components/base/FormField';
import FormikSelect from 'components/base/CustomSelect/FormikSelect';
import Button from 'components/base/Button';
import { useWavesurfer } from 'components/base/AudioPlayer';
import ktpaTower from 'static/KTPA2-Twr-Sep-19-2024-1700Z.mp3';
import ktpaApproach from 'static/KPIE1-App-118800-Sep-19-2024-1700Z.mp3';

import FormikInput from 'components/base/CustomInput/FormikInput';

import Spinner from 'components/base/Spinner';
import { handleToasts } from 'utils/handleThunkRequest';

const defaultInitialValues = {
  timeSlot: '1200Z',
  date: new Date().toISOString().slice(0, 10),
  feed: '',
};

const timeSlotsOptions = [
  { value: '0000Z', name: '0000-0030' },
  { value: '0030Z', name: '0030-0100' },
  { value: '0100Z', name: '0100-0130' },
  { value: '0130Z', name: '0130-0200' },
  { value: '0200Z', name: '0200-0230' },
  { value: '0230Z', name: '0230-0300' },
  { value: '0300Z', name: '0300-0330' },
  { value: '0330Z', name: '0330-0400' },
  { value: '0400Z', name: '0400-0430' },
  { value: '0430Z', name: '0430-0500' },
  { value: '0500Z', name: '0500-0530' },
  { value: '0530Z', name: '0530-0600' },
  { value: '0600Z', name: '0600-0630' },
  { value: '0630Z', name: '0630-0700' },
  { value: '0700Z', name: '0700-0730' },
  { value: '0730Z', name: '0730-0800' },
  { value: '0800Z', name: '0800-0830' },
  { value: '0830Z', name: '0830-0900' },
  { value: '0900Z', name: '0900-0930' },
  { value: '0930Z', name: '0930-1000' },
  { value: '1000Z', name: '1000-1030' },
  { value: '1030Z', name: '1030-1100' },
  { value: '1100Z', name: '1100-1130' },
  { value: '1130Z', name: '1130-1200' },
  { value: '1200Z', name: '1200-1230' },
  { value: '1230Z', name: '1230-1300' },
  { value: '1300Z', name: '1300-1330' },
  { value: '1330Z', name: '1330-1400' },
  { value: '1400Z', name: '1400-1430' },
  { value: '1430Z', name: '1430-1500' },
  { value: '1500Z', name: '1500-1530' },
  { value: '1530Z', name: '1530-1600' },
  { value: '1600Z', name: '1600-1630' },
  { value: '1630Z', name: '1630-1700' },
  { value: '1700Z', name: '1700-1730' },
  { value: '1730Z', name: '1730-1800' },
  { value: '1800Z', name: '1800-1830' },
  { value: '1830Z', name: '1830-1900' },
  { value: '1900Z', name: '1900-1930' },
  { value: '1930Z', name: '1930-2000' },
  { value: '2000Z', name: '2000-2030' },
  { value: '2030Z', name: '2030-2100' },
  { value: '2100Z', name: '2100-2130' },
  { value: '2130Z', name: '2130-2200' },
  { value: '2200Z', name: '2200-2230' },
  { value: '2230Z', name: '2230-2300' },
  { value: '2300Z', name: '2300-2330' },
  { value: '2330Z', name: '2330-0000' },
];

const ArchiveFeedForm = () => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const { fetching, data } = useSelector((state) => state.atc.live);

  const [feedOptions, setFeedOptions] = useState([]);
  useEffect(() => {
    const options = data.filter((feed) => feed.url).map((feed) => ({ name: feed.name, value: feed.code }));
    options.unshift({ value: '', name: '', disabled: true });
    setFeedOptions(options);
  }, [data]);

  const formValidation = (values) => {
    const errors = {};

    if (!values.date) {
      errors.date = 'Date is required';
    }

    if (!values.feed) {
      errors.feed = 'Feed is required';
    }

    if (!values.timeSlot) {
      errors.timeSlot = 'Time is required';
    }

    return errors;
  };
  const [selectedAudio, setSelectedAudio] = useState();

  const formOnSubmit = (values, { setSubmitting }) => {
    const { date, timeSlot, feed } = values;

    // then setSubmitting
    dispatch(fetchAtcArchiveRecording({ date, timeSlot })).then(
      handleToasts({
        successCallback: (data) => {
          if (feed === 'ktpa2') {
            setSelectedAudio(ktpaTower);
          }

          if (feed === 'kpie1_app_118800') {
            setSelectedAudio(ktpaApproach);
          }
          setSubmitting(false);
        },
        errorCallback: (data) => {
          setSubmitting(false);
        },
      })
    );
  };

  const { wavesurfer, isPlaying } = useWavesurfer({
    container: containerRef,
    url: selectedAudio,
    normalize: false,
    waveColor: '#47baec',
    height: 100,
  });

  const onPlayPause = () => {
    if (wavesurfer) {
      wavesurfer.playPause();
    }
  };

  return (
    <div className="atc__block atc__block-live">
      {fetching ? (
        <Spinner className="atc__spinner" />
      ) : (
        <>
          <Formik
            initialValues={defaultInitialValues}
            onSubmit={formOnSubmit}
            validate={formValidation}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ isSubmitting, setSubmitting }) => (
              <Form className="atc__archive-form">
                <div className="atc__archive-form-row">
                  <FormField label="Select Feed" name="feed" mods={{ condensed: true }} className="fw125">
                    <FormikSelect name="feed" select options={feedOptions} search condensed required />
                  </FormField>
                </div>
                <div className="atc__archive-form-row">
                  <FormField label="Date" name="date" mods={{ condensed: true }} className="fw100">
                    <FormikInput name="date" type="date" required pattern="^\d{4}-\d{2}-\d{2}$" condensed />
                  </FormField>
                  <FormField label="Time Slot" name="timeSlot" mods={{ condensed: true }}>
                    <FormikSelect name="timeSlot" select options={timeSlotsOptions} condensed required />
                  </FormField>

                  <Button
                    mods={{ action: true, loading: isSubmitting, small: true }}
                    disabled={isSubmitting}
                    className="atc__archive-form-button"
                    type="submit"
                  >
                    Load
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          <div ref={containerRef} className="atc__archive-feed-player" />
          <Button mods={{ action: true, small: true }} onClick={onPlayPause} className="atc__archive-feed-button">
            {isPlaying ? 'Pause' : 'Play'}
          </Button>
        </>
      )}
    </div>
  );
};

export default ArchiveFeedForm;
