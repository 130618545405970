import React, { lazy } from 'react';
import Page from 'components/common/Page';
const ExternalDatasetLADD = lazy(() => import('./ExternalDatasetLADD'));

const ExternalDatasetLADDPage = () => {
  return (
    <Page>
      <ExternalDatasetLADD />
    </Page>
  );
};

export default ExternalDatasetLADDPage;
