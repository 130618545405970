import React, { lazy } from 'react';
import Page from 'components/common/Page';
const AircraftsEdit = lazy(() => import('./AircraftsEdit'));

const AircraftsEditPage = () => {
  return (
    <Page>
      <AircraftsEdit />
    </Page>
  );
};

export default AircraftsEditPage;
