import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './FormField.css';

const FormField = ({ label, title, name, children, className, classNameLabel, mods = {} }) => {
  return (
    <div
      className={clsx('formfield', className, {
        formfield_centered: mods.centered,
        formfield_checkbox: mods.checkbox,
        formfield_row: mods.row,
        formfield_condensed: mods.condensed,
        formfield_largeLabel: mods.largeLabel,
      })}
    >
      {mods.row && children}
      <label htmlFor={name} className={clsx(classNameLabel, 'formfield__label')} title={title}>
        {label}
      </label>
      {!mods.row && children}
    </div>
  );
};

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  classNameLabel: PropTypes.string,
  mods: PropTypes.shape({
    centered: PropTypes.bool,
    checkbox: PropTypes.bool,
    row: PropTypes.bool,
    condensed: PropTypes.bool,
  }),
};

export default FormField;
