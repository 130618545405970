import React from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';

const DNDWrapper = ({ children, moveBox }) => {
  const [, drop] = useDrop({
    accept: 'modal',
    drop(item, monitor) {
      const delta = monitor.getDifferenceFromInitialOffset();
      const left = Math.round(item.left + delta.x);
      const top = Math.round(item.top + delta.y);
      moveBox(item.id, left, top);
      return undefined;
    },
  });

  return (
    <div className="page-content" ref={drop}>
      {children}
    </div>
  );
};

DNDWrapper.propTypes = {
  children: PropTypes.node,
  moveBox: PropTypes.func.isRequired,
};

export default DNDWrapper;
