import React from 'react';
import PropTypes from 'prop-types';
import './Pagination.css';

const Pagination = ({
  canPreviousPage,
  canNextPage,
  gotoPage,
  previousPage,
  nextPage,
  pageCount,
  pageIndex,
  handlePageChange,
}) => {
  const makePagesList = (maxPages, currentPage) => {
    if (maxPages === 0) return [0];
    const displayedPages = 4;
    let pagesStart;
    let pagesEnd;

    if (maxPages <= displayedPages) {
      pagesStart = 1;
      pagesEnd = maxPages;
    } else {
      pagesStart = Math.max(1, currentPage - displayedPages / 2 - 1);
      pagesEnd = Math.min(maxPages, currentPage + displayedPages / 2);

      if (pagesStart === 1) {
        pagesEnd = displayedPages;
      } else if (pagesEnd === maxPages) {
        pagesStart = Math.max(1, maxPages - displayedPages - 1);
      }
    }

    const result = [];
    for (let i = pagesStart; i <= pagesEnd; i += 1) {
      result.push(i - 1);
    }

    if (maxPages > displayedPages) {
      while (result.length < Math.min(maxPages, displayedPages + 2)) {
        result.push(result[result.length - 1] + 1);
      }
    }

    return result;
  };

  return (
    <div className="table-btn-group">
      <button
        type="button"
        className="table-btn-group__btn"
        onClick={() => {
          gotoPage(0);
          handlePageChange(0);
        }}
        disabled={!canPreviousPage}
      >
        First
      </button>
      <button
        type="button"
        className="table-btn-group__btn"
        onClick={() => {
          previousPage();
          handlePageChange(pageIndex - 1);
        }}
        disabled={!canPreviousPage}
      >
        Previous
      </button>
      {makePagesList(pageCount, pageIndex + 1).map((page) => {
        return (
          <button
            key={page}
            type="button"
            className="table-btn-group__btn table-btn-group__btn_page"
            onClick={() => {
              gotoPage(page);
              handlePageChange(page);
            }}
            disabled={page === pageIndex}
          >
            {page + 1}
          </button>
        );
      })}
      <button
        type="button"
        className="table-btn-group__btn"
        onClick={() => {
          nextPage();
          handlePageChange(pageIndex + 1);
        }}
        disabled={!canNextPage}
      >
        Next
      </button>
      <button
        type="button"
        className="table-btn-group__btn"
        onClick={() => {
          gotoPage(pageCount - 1);
          handlePageChange(pageCount - 1);
        }}
        disabled={!canNextPage}
      >
        Last
      </button>
    </div>
  );
};

Pagination.propTypes = {
  canPreviousPage: PropTypes.bool,
  canNextPage: PropTypes.bool,
  gotoPage: PropTypes.func,
  previousPage: PropTypes.func,
  nextPage: PropTypes.func,
  handlePageChange: PropTypes.func,
  pageCount: PropTypes.number,
  pageIndex: PropTypes.number,
};

export default Pagination;
