import React, { lazy } from 'react';
import Page from 'components/common/Page';
const UsersLogins = lazy(() => import('./UsersLogins'));

const UsersLoginsPage = () => {
  return (
    <Page>
      <UsersLogins />
    </Page>
  );
};

export default UsersLoginsPage;
