import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

const initialState = {
  usersLogins: [],
  fetching: true,
  dataFetched: false,
};

export const fetchUsersLogins = createAsyncThunk('users/fetchUserLogins', async () => {
  return api.getAirportLogins();
});

const handlePendingAction = (state) => {
  state.fetching = true;
};

const handleRejectedAction = (state) => {
  state.fetching = false;
};

export const usersLoginsSlice = createSlice({
  name: 'usersLogins',
  initialState,
  extraReducers: {
    [fetchUsersLogins.pending]: handlePendingAction,

    [fetchUsersLogins.fulfilled]: (state, action) => {
      state.usersLogins = action.payload.map((user) => {
        const { airport = {} } = user;
        const { airport_name, icao, city, faa, lat, lon, elevation, createdAt } = airport;
        return { ...user, airport_name, icao, city, faa, lat, lon, elevation, createdAt };
      });
      state.fetching = false;
    },

    [fetchUsersLogins.rejected]: handleRejectedAction,
  },
  reducers: {
    setDataFetched(state) {
      state.dataFetched = true;
    },
  },
});

export default usersLoginsSlice.reducer;
export const { setDataFetched } = usersLoginsSlice.actions;
