import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { aircraftListTupleToObject } from 'utils/aircrafts.utils';
import api from 'api/api';

const initialState = {
  list: [],
  meta: { today: null, yesterday: null, month: null, year: null, lastYear: null },
  aircrafts: {},
  fetching: true,
  fetchAircraftRequestId: '',
  dataFetched: false,
  total: 0,
  filteredTotal: 0,
};

export const fetchAircrafts = createAsyncThunk('aircrafts/fetchAll', async (paginationParams) => {
  return api.getAircraftDBList(paginationParams);
});

export const fetchBasedAircrafts = createAsyncThunk('aircrafts/fetchBased', async () => {
  return api.getBasedAircraftList();
});

export const fetchAircraftById = createAsyncThunk('aircrafts/fetchById', async (id) => {
  return api.getAircraftById(id);
});

export const updateAircraft = createAsyncThunk('aircrafts/update', async (aircraft) => {
  return api.updateAircraft(aircraft);
});

export const archiveAircraft = createAsyncThunk('aircrafts/archive', async (id) => {
  return api.archiveAircraft(id);
});

export const removeBasedAircraft = createAsyncThunk('aircrafts/removeBased', async (data) => {
  return api.removeBasedAircraft(data);
});

export const batchUpdateAircraftsCategory = createAsyncThunk('aircrafts/batch', async (data) => {
  return api.batchUpdateAircraftsCategory(data);
});

const handlePendingAction = (state) => {
  state.fetching = true;
};

const handleRejectedAction = (state) => {
  state.fetching = false;
};

const handleUpdateAircraft = (state, action) => {
  // why don't update
  const updatedAircraft = action.payload;
  let aircraftListIndex = -1;
  const aircraftInTheLIst = state.list.find((aircraft, i) => {
    const isExactAircraft = aircraft.id === updatedAircraft.id;
    if (isExactAircraft) aircraftListIndex = i;
    return isExactAircraft;
  });
  const aircraftListObject = aircraftInTheLIst;
  const aircraft = state.aircrafts[updatedAircraft.id];
  if (aircraft) Object.assign(aircraft, action.payload);
  if (aircraftInTheLIst) {
    Object.assign(aircraftListObject, action.payload);
    state.list[aircraftListIndex] = aircraftListObject;
  }

  state.fetching = false;
};

export const aircraftsSlice = createSlice({
  name: 'aircrafts',
  initialState,
  extraReducers: {
    [fetchAircrafts.pending]: (state, action) => {
      state.fetching = true;
      state.fetchAircraftRequestId = action.meta.requestId;
    },
    [fetchBasedAircrafts.pending]: handlePendingAction,
    [fetchAircraftById.pending]: handlePendingAction,
    [batchUpdateAircraftsCategory.pending]: handlePendingAction,

    [fetchAircrafts.fulfilled]: (state, action) => {
      if (action.meta.requestId !== state.fetchAircraftRequestId) {
        return;
      }

      state.list = action.payload?.data?.map(aircraftListTupleToObject);
      state.total = action.payload.meta?.total;
      state.filteredTotal = action.payload.meta?.filteredTotal;
      state.fetching = false;
    },
    [fetchBasedAircrafts.fulfilled]: (state, action) => {
      state.list = action.payload;
      state.fetching = false;
    },
    [fetchAircraftById.fulfilled]: (state, action) => {
      const aircraft = action.payload;
      state.aircrafts[aircraft.id] = aircraft;
      state.fetching = false;
    },

    [removeBasedAircraft.fulfilled]: (state, action) => {
      state.list = state.list.filter((acft) => acft.id !== action.payload.aircraftId);
    },
    [updateAircraft.fulfilled]: handleUpdateAircraft,
    [batchUpdateAircraftsCategory.fulfilled]: (state, action) => {
      const idhashmap = {};
      action.payload.idList.forEach((id) => {
        idhashmap[id] = id;
      });

      const aircrafts = state.list.map((a) => {
        const isUpdated = idhashmap[a.id];
        if (!isUpdated) return a;

        const { type, faaCategory, aircraftDesignGroup, operatorDetails, iconType } = action.payload;

        if (type) {
          return { ...a, type };
        }

        if (iconType) {
          return { ...a, iconType };
        }

        if (faaCategory) {
          return { ...a, faaCategory };
        }

        if (aircraftDesignGroup) {
          return { ...a, aircraftDesignGroup };
        }

        if (operatorDetails) {
          return { ...a, operatorDetails };
        }

        return a;
      });
      state.list = [...aircrafts];
      state.fetching = false;
    },
    [fetchAircrafts.rejected]: handleRejectedAction,
    [fetchBasedAircrafts.rejected]: handleRejectedAction,
    [fetchAircraftById.rejected]: handleRejectedAction,
  },
  reducers: {
    setFetching(state, action) {
      state.fetching = Boolean(action.payload);
    },
    setDataFetched(state) {
      state.dataFetched = true;
    },
    updateOperationsBasedMeta(state, action) {
      state.meta = action.payload;
    },
    incrementBasedOperationMeta(state) {
      if (typeof state.meta?.today === 'number') {
        state.meta.today += 1;
        state.meta.month += 1;
        state.meta.year += 1;
      }
    },
  },
});

export default aircraftsSlice.reducer;
export const { setFetching, setDataFetched, updateOperationsBasedMeta, incrementBasedOperationMeta } =
  aircraftsSlice.actions;
