import React, { lazy } from 'react';
import Page from 'components/common/Page';
const Contact = lazy(() => import('./Contact'));

const ContactPage = () => {
  return (
    <Page showPlane>
      <Contact />
    </Page>
  );
};

export default ContactPage;
