import { createSlice } from '@reduxjs/toolkit';

/**
 * @typedef {Object} ScheduledFlightsState
 * @property {Operation[]} flights - Contains list of operations which indicates flight status
 * @property {boolean} loading - Is list loading
 */
const initialState = {
  list: [],
  loading: true,
};

export const scheduledFlightsSlice = createSlice({
  name: 'scheduledFlights',
  initialState,
  reducers: {
    updateScheduledFlights(state, action) {
      const { scheduledFlights = [] } = action.payload;

      state.list = scheduledFlights;
      state.loading = false;
    },
  },
});

export default scheduledFlightsSlice.reducer;

export const { updateScheduledFlights } = scheduledFlightsSlice.actions;
