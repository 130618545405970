import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

/**
 * @typedef {Object} Units
 * @property {string} altitude - Altitude, Height, Elevation ft|m
 * @property {string} airPressure - Air pressure inHg|hPa
 * @property {string} runwayLength - Runway Length ft|m
 * @property {string} visibility - Visibility SM|m
 * @property {string} speed - Speed (IAS, TAS, GS) kts|km/h
 * @property {string} temperature - Temperature/Dewp. °F|°C
 * @property {string} mass - Mass lb|kg
 * @property {string} verticalSpeed - Vertical Speed ft/min
 */

/**
 * @typedef {Object} SettingsState
 * @property {Units} measureUnits - Contains settings for units of measure
 * @property {Object} systemSettings - Contains settings for the whole system
 */
const initialState = {
  measureUnits: {
    altitude: 'ft',
    airPressure: 'inHg',
    runwayLength: 'ft',
    visibility: 'SM',
    speed: 'kts',
    temperature: '°F',
    mass: 'lb',
    verticalSpeed: 'ft/min',
    lat: '°Dec',
    lon: '°Dec',
    zoomDiameter: 'NM',
    time: 'HH:MM',
    date: 'MM/DD/YYYY',
    frequency: 'MHz',
    interval: 'sec',
  },
  systemSettings: {},
  userSettings: {},
  fetching: true,
};

export const fetchSettings = createAsyncThunk('settings/fetch', async (params) => {
  return api.getSettings(params);
});

export const updateSettings = createAsyncThunk('settings/update', async (geofence) => {
  return api.updateSettings(geofence);
});

const handlePendingAction = (state) => {
  state.fetching = true;
};

const handleRejectedAction = (state) => {
  state.fetching = false;
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSettings.pending]: handlePendingAction,
    [fetchSettings.fulfilled]: (state, action) => {
      state.systemSettings = action.payload;
      state.fetching = false;
    },
    [updateSettings.fulfilled]: (state, action) => {
      state.systemSettings = action.payload;
    },
    [fetchSettings.rejected]: handleRejectedAction,
  },
});

export default settingsSlice.reducer;
