import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/base/Button';
import legendBrefQcd from 'static/legend.bref.qcd.png';
import legendBrefRaw from 'static/legend.bref.raw.png';
import legendCrefQcd from 'static/legend.cref.qcd.png';
import legendCrefRaw from 'static/legend.cref.raw.png';
import legendPcpnTyp from 'static/legend.pcpn.typ.png';
import legendNeetV18 from 'static/legend.neet.v18.png';
import {
  WEATHER_BREF_QCD,
  WEATHER_BREF_RAW,
  WEATHER_CREF_RAW,
  WEATHER_CREF_QCD,
  WEATHER_PCNP_TYP,
  WEATHER_NEET_V18,
} from '../../baseLayers';

const typeToLegend = {
  [WEATHER_BREF_QCD]: legendBrefQcd,
  [WEATHER_BREF_RAW]: legendBrefRaw,
  [WEATHER_CREF_RAW]: legendCrefRaw,
  [WEATHER_CREF_QCD]: legendCrefQcd,
  [WEATHER_PCNP_TYP]: legendPcpnTyp,
  [WEATHER_NEET_V18]: legendNeetV18,
};

const WeatherRadarLegend = ({ title, type, url, onToggle, isPlaying, dateTime }) => {
  return (
    type && (
      <div className="weather-radar-legend" title={title}>
        <img src={url || typeToLegend[type]} alt={type} />
        <Button onClick={onToggle} className="weather-radar-legend__control">
          {isPlaying ? 'Pause' : 'Play'}
        </Button>
        <div className="weather-radar-legend__timestamp">{dateTime}</div>
      </div>
    )
  );
};

WeatherRadarLegend.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
  onToggle: PropTypes.func,
  isPlaying: PropTypes.bool,
  dateTime: PropTypes.string,
};

export default WeatherRadarLegend;
