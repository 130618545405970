import React, { useEffect, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { fetchUser } from 'redux/modules/auth';
import focusTypeDetection from 'utils/focusTypeDetection';
import FullPageLoader from 'components/base/FullPageLoader';
import PageTitle from 'components/common/PageTitle';
import NotFound from 'pages/NotFound';

import appRoutes from './routes';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || 'GTM-WX8NBX9',
};

/**
 * Renders passed routes
 * @param {Array.<RouteConfig>} routes
 */
function renderRoutes(routes, userInfo, parentPermission) {
  const { permissionsList, isDisclaimerRead, isLoggedIn } = userInfo;
  return (
    <Suspense fallback={<FullPageLoader />}>
      <Switch>
        {routes.map((route) => {
          const { path, exact, title, children, authRoute } = route;
          const disclaimerRoute = path === '/disclaimer';
          const isUserAuthorized = isLoggedIn;
          if (!authRoute && !isUserAuthorized) {
            return <Redirect key={`${path || title}Redirect`} to="/" />;
          }

          if (authRoute && isUserAuthorized) {
            return <Redirect key={`${path || title}Redirect`} to="/dashboard" />;
          }

          if (!disclaimerRoute && isUserAuthorized && !isDisclaimerRead) {
            return <Redirect key={`${path || title}Redirect`} to="/disclaimer" />;
          }

          const renderChildren = () => {
            return renderRoutes(children, userInfo, route.permission);
          };
          const renderElement = (routeChildren) => {
            const permissionExists = Boolean(route.permission || parentPermission);
            const hasPermission = [route.permission, parentPermission]
              .filter(Boolean) // filter only existing permissions
              .some((per) => permissionsList.includes(per));
            if (permissionExists && !hasPermission) {
              return <Redirect to="/dashboard" />;
            }

            return (
              <PageTitle title={title}>
                <route.element>{routeChildren}</route.element>
              </PageTitle>
            );
          };

          const renderRoute = () => {
            if (route.element && children) return renderElement(renderChildren());
            return route.element ? renderElement() : renderChildren();
          };

          return (
            <Route key={path || title} path={path} exact={exact}>
              {renderRoute()}
            </Route>
          );
        })}
        <Route path="*">
          <PageTitle title="Not Found">
            <NotFound />
          </PageTitle>
        </Route>
      </Switch>
    </Suspense>
  );
}

const App = () => {
  const dispatch = useDispatch();
  const { userInfo, fetched } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(fetchUser());
    document.addEventListener(
      'click',
      () => {
        setTimeout(() => {
          TagManager.initialize(tagManagerArgs);
        }, 2000);
      },
      {
        once: true,
      }
    );
  }, [dispatch]);

  // add focus type detection listeners
  useEffect(() => {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    if (isMac) document.body.classList.add('macos');
    return focusTypeDetection();
  }, []);
  if (!fetched) return <FullPageLoader />;

  /* Order auth route for correct redirection */
  const routes = appRoutes.sort((next, prev) => {
    if (prev.authRoute && !next.authRoute) {
      return userInfo.isLoggedIn ? -1 : 1;
    }
    return 0;
  });
  return renderRoutes(routes, userInfo);
};

export default App;
