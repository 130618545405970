import React, { lazy } from 'react';
import Page from 'components/common/Page';
const Reports = lazy(() => import('./Reports'));

const ReportsPage = () => {
  return (
    <Page>
      <Reports />
    </Page>
  );
};

export default ReportsPage;
