import React from 'react';
import logo from 'static/logo_grey.svg';

import './ErrorPage.css';

const ErrorPage = () => {
  return (
    <div className="error-page">
      <a className="error-page__header" href="/">
        <img src={logo} alt="Virtower" className="top-logo" />
      </a>
      <h1 className="error-page__text">
        Oops... <br /> Something went wrong
      </h1>
    </div>
  );
};

export default ErrorPage;
