import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

/**
 * @typedef {Object} Activity
 * @property {number} id
 * @property {boolean} active
 * @property {string} name
 * @property {string} remarks
 * @property {number} customerId
 * @property {number[]} sourcesPriority
 * @property {Object} jsonLogic
 * @property {boolean} isAlarmListNotification
 * @property {boolean} isEmailNotification
 * @property {boolean} isLanding
 * @property {boolean} isModalOnMapNotification
 * @property {boolean} isShownOnFlights
 * @property {boolean} isTakeOff
 * @property {string} eventName
 * @property {string} eventColor
 * @property {string} customerICAO
 * @property {number} customerId
 * @property {number|null} triggerPeriod
 */

/**
 * @typedef {Object} ActivityState
 * @property {Activity[]} activities - List of activities
 * @property {boolean} fetching
 * @property {boolean} dataFetched
 */
const initialState = {
  activities: [],
  fetching: true,
  dataFetched: false,
};

export const fetchActivities = createAsyncThunk('activity/fetchAll', async () => {
  return api.getActivities();
});

export const fetchActivity = createAsyncThunk('activity/fetchOne', async (activityId) => {
  return api.getActivity(activityId);
});

export const createActivity = createAsyncThunk('activity/create', async (activity) => {
  return api.createActivity(activity);
});

export const updateActivity = createAsyncThunk('activity/update', async (activity) => {
  return api.updateActivity(activity);
});

export const patchActivity = createAsyncThunk('activity/patch', async (activityDto) => {
  return api.patchActivity(activityDto);
});

export const deleteActivity = createAsyncThunk('activity/delete', async (activityId) => {
  return api.deleteActivity(activityId);
});

const handlePendingAction = (state) => {
  state.fetching = true;
};

const handleRejectedAction = (state) => {
  state.fetching = false;
};

export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  extraReducers: {
    [fetchActivities.pending]: handlePendingAction,
    [fetchActivity.pending]: handlePendingAction,

    [fetchActivities.fulfilled]: (state, { payload }) => {
      state.activities = payload;
      state.fetching = false;
    },
    [fetchActivity.fulfilled]: (state, { payload }) => {
      if (!state.activities.find((g) => g.id === +payload.id)) {
        state.activities = [...state.activities, payload];
      }
      state.fetching = false;
    },
    [createActivity.fulfilled]: (state, { payload }) => {
      state.activities.push(payload);
      state.fetching = false;
    },
    [updateActivity.fulfilled]: (state, { payload }) => {
      const activity = state.activities.find((g) => g.id === payload.id);
      if (activity) Object.assign(activity, payload);
      state.fetching = false;
    },
    [patchActivity.fulfilled]: (state, { payload }) => {
      const activity = state.activities.find((g) => g.id === payload.id);
      if (activity) Object.assign(activity, payload);
      state.fetching = false;
    },
    [deleteActivity.fulfilled]: (state, { payload }) => {
      state.activities = state.activities.filter((g) => {
        return g.id !== +payload.id;
      });
    },
    [fetchActivities.rejected]: handleRejectedAction,
    [fetchActivity.rejected]: handleRejectedAction,
  },
  reducers: {
    setDataFetched(state) {
      state.dataFetched = true;
    },
  },
});

export default activitySlice.reducer;
export const { setDataFetched } = activitySlice.actions;
