import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

const initialState = {
  customers: [],
  fetching: true,
  dataFetched: false,
};

export const fetchCustomers = createAsyncThunk('customers/fetchAll', async () => {
  return api.getCustomers();
});

export const fetchCustomerById = createAsyncThunk('customers/fetchById', async (id) => {
  return api.getCustomerById(id);
});

export const createCustomer = createAsyncThunk('customers/create', async (customer) => {
  return api.createCustomer(customer);
});

export const updateCustomer = createAsyncThunk('customers/update', async (customer) => {
  return api.updateCustomer(customer);
});

export const updateCustomerSettings = createAsyncThunk('customers/updateSettings', async (customer) => {
  return api.updateCustomerSettings(customer);
});

export const deleteCustomer = createAsyncThunk('customers/delete', async (id) => {
  return api.deleteCustomer(id);
});

export const batchUpdateCustomerGroup = createAsyncThunk('customers/batch-customer-group', async (data) => {
  return api.batchUpdateCustomerGroup(data);
});

const handlePendingAction = (state) => {
  state.fetching = true;
};

const handleRejectedAction = (state) => {
  state.fetching = false;
};

const convertCustomerGroupsToString = (customerGroups) => {
  return customerGroups?.length ? customerGroups.map(({ name }) => name).join(', ') : '';
};

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  extraReducers: {
    [fetchCustomers.pending]: handlePendingAction,
    [fetchCustomerById.pending]: handlePendingAction,
    [batchUpdateCustomerGroup.pending]: handlePendingAction,

    [fetchCustomers.fulfilled]: (state, action) => {
      state.customers = action.payload.map(({ customerGroups, ...customer }) => {
        return {
          ...customer,
          customerGroupString: convertCustomerGroupsToString(customerGroups),
        };
      });
      state.fetching = false;
    },
    [fetchCustomerById.fulfilled]: (state, action) => {
      if (!state.customers.find((customer) => customer.id === action.payload.id)) {
        const { customerGroups, ...customer } = action.payload;
        const fetchedCustomer = {
          ...customer,
          customerGroupString: convertCustomerGroupsToString(customerGroups),
        };
        state.customers = [...state.customers, fetchedCustomer];
        state.fetching = false;
      }
    },
    [createCustomer.fulfilled]: (state, action) => {
      const { customerGroups, ...customer } = action.payload;
      const createdCustomer = {
        ...customer,
        customerGroupString: convertCustomerGroupsToString(customerGroups),
      };
      state.customers.push(createdCustomer);
    },
    [updateCustomer.fulfilled]: (state, action) => {
      const customer = state.customers.find((c) => c.id === action.payload.id);
      if (customer) Object.assign(customer, action.payload);
    },
    [deleteCustomer.fulfilled]: (state, action) => {
      state.customers = state.customers.filter((c) => {
        return c.id !== +action.payload.id;
      });
    },

    [batchUpdateCustomerGroup.fulfilled]: (state, action) => {
      const idhashmap = {};
      action.payload.idList.forEach((id) => {
        idhashmap[id] = id;
      });
      const customerGroups = action.payload?.customerGroups;

      state.customers = state.customers.map((c) => {
        const isUpdated = idhashmap[c.id];
        if (!isUpdated) return c;

        const updatedCustomer = {
          ...c,
          customerGroupString: convertCustomerGroupsToString(customerGroups),
        };
        return updatedCustomer;
      });
      state.fetching = false;
    },
    [fetchCustomers.rejected]: handleRejectedAction,
    [fetchCustomerById.rejected]: handleRejectedAction,
  },
  reducers: {
    setDataFetched(state) {
      state.dataFetched = true;
    },
  },
});

export default customersSlice.reducer;
export const { setDataFetched } = customersSlice.actions;
