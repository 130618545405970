import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

const initialState = {
  fetching: true,
  report: [],
  dateFormat: 'utc',
  dateStart: '',
  dateEnd: '',
};

export const createReport = createAsyncThunk('report/create', async (data) => {
  return api.createReport(data);
});

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setDateFormat(state, action) {
      state.dateFormat = action.payload;
    },
    setDateStart(state, action) {
      state.dateStart = action.payload;
    },
    setDateEnd(state, action) {
      state.dateEnd = action.payload;
    },
  },
  extraReducers: {
    [createReport.fulfilled]: (state, action) => {
      state.fetching = false;
    },
  },
});

export default reportSlice.reducer;

export const { setDateFormat, setDateStart, setDateEnd } = reportSlice.actions;
