import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter, resetFilters } from 'redux/modules/map';
import { MAP_CONTROLS, ACCORDION_CONTROLS } from 'utils/globalConstants';
import { permissions } from 'utils/userPermissions';
import AccordionTab from 'components/base/AccordionTab';
import { toggleModalAccordion } from 'redux/modules/modal';
import DNDModal from 'components/base/DNDModal';
import CustomInput from 'components/base/CustomInput';
import CustomSelect from 'components/base/CustomSelect';
import Button from 'components/base/Button';

import './FiltersModal.css';

const FiltersModal = ({ top = 0, left = 0, width = 350, height }) => {
  const dispatch = useDispatch();
  const { accordions } = useSelector((state) => state.modal);
  const { filters } = useSelector((state) => state.map);
  const { permissionsList } = useSelector((state) => state.auth.userInfo);
  const isDebugMode = permissionsList.includes(permissions.OPTION_DEBUG);
  const filterKeys = Object.keys(filters);
  const aircraftRelatedFiltersKeys = filterKeys.filter((key) => {
    return filters[key].filterCategory === 'aircraft';
  });
  const flightRelatedFiltersKeys = filterKeys.filter((key) => {
    return filters[key].filterCategory === 'flight';
  });
  const debugRelatedFiltersKeys = filterKeys.filter((key) => {
    return filters[key].filterCategory === 'debug';
  });
  const filtersAppliedAmount = Object.values(filters).filter((filter) => {
    const value = filter.value?.[0] || filter.value?.[1];
    return value && value !== 'any';
  }).length;

  const renderList = (keysList) => {
    return keysList.map((key) => {
      const { alias, value, options, placeholder } = filters[key];

      return (
        <React.Fragment key={alias}>
          <div className="map-filters_row">
            <div className="map-filters_formfield">
              {!options &&
                value.map((val, index) => {
                  return (
                    <CustomInput
                      // eslint-disable-next-line react/no-array-index-key
                      key={alias + index}
                      value={val}
                      onChange={(e) => {
                        dispatch(
                          setFilter({
                            filter: key,
                            valueIndex: index,
                            value: e.target.value,
                          })
                        );
                      }}
                      placeholder={placeholder[index]}
                      className="map-filters__input"
                      condensed
                    />
                  );
                })}
              {options && (
                <CustomSelect
                  name={alias}
                  options={options}
                  value={value[0]}
                  className="map-filters__input"
                  onChange={(val) => {
                    dispatch(
                      setFilter({
                        filter: key,
                        valueIndex: 0,
                        value: val,
                      })
                    );
                  }}
                  condensed
                />
              )}
            </div>
            <div className="map-filters_alias">{alias}</div>
          </div>
        </React.Fragment>
      );
    });
  };

  return (
    <DNDModal
      id={MAP_CONTROLS.FILTERS}
      left={left}
      top={top}
      title="Filter"
      width={width}
      height={height}
      actions={
        <Button
          mods={{ small: true }}
          type="button"
          onClick={() => {
            dispatch(resetFilters());
          }}
          disabled={filtersAppliedAmount === 0}
        >
          RESET
        </Button>
      }
    >
      <div className="map-filters">
        <div className="dndmodal__divider" />
        <AccordionTab
          label="Aircraft Related"
          name={ACCORDION_CONTROLS.FILTERS_AIRCRAFT}
          opened={accordions[ACCORDION_CONTROLS.FILTERS_AIRCRAFT]}
          setOpened={(checked) =>
            dispatch(toggleModalAccordion({ name: ACCORDION_CONTROLS.FILTERS_AIRCRAFT, state: checked }))
          }
        >
          {renderList(aircraftRelatedFiltersKeys)}
        </AccordionTab>
        <div className="dndmodal__divider" />
        <AccordionTab
          label="Flight Related"
          name={ACCORDION_CONTROLS.FILTERS_FLIGHT}
          opened={accordions[ACCORDION_CONTROLS.FILTERS_FLIGHT]}
          setOpened={(checked) =>
            dispatch(toggleModalAccordion({ name: ACCORDION_CONTROLS.FILTERS_FLIGHT, state: checked }))
          }
        >
          {renderList(flightRelatedFiltersKeys)}
        </AccordionTab>
        {isDebugMode && (
          <>
            <div className="dndmodal__divider" />
            <AccordionTab
              label="Debug Related"
              name={ACCORDION_CONTROLS.FILTERS_DEBUG}
              opened={accordions[ACCORDION_CONTROLS.FILTERS_DEBUG]}
              setOpened={(checked) =>
                dispatch(toggleModalAccordion({ name: ACCORDION_CONTROLS.FILTERS_DEBUG, state: checked }))
              }
            >
              {renderList(debugRelatedFiltersKeys)}
            </AccordionTab>
          </>
        )}
      </div>
    </DNDModal>
  );
};

FiltersModal.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default FiltersModal;
