import React, { lazy } from 'react';
import Page from 'components/common/Page';
const ActivitiesEdit = lazy(() => import('./ActivitiesEdit'));

const ActivitiesEditPage = () => {
  return (
    <Page>
      <ActivitiesEdit />
    </Page>
  );
};

export default ActivitiesEditPage;
