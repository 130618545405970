import React from 'react';

import plane from './plane.svg';
import './PlaneBackground.css';

const PlaneBackground = () => {
  const styles = {
    backgroundImage: `url(${plane})`,
  };

  return (
    <div className="plane">
      <div className="plane-image" style={styles} />
    </div>
  );
};

export default PlaneBackground;
