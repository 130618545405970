import React, { lazy } from 'react';
import Page from 'components/common/Page';
const Settings = lazy(() => import('./Settings'));

const SettingsPage = () => {
  return (
    <Page>
      <Settings />
    </Page>
  );
};

export default SettingsPage;
