import React, { lazy } from 'react';
import Page from 'components/common/Page';
const Geofences = lazy(() => import('./Geofences'));

const GeofencesPage = () => {
  return (
    <Page>
      <Geofences />
    </Page>
  );
};

export default GeofencesPage;
