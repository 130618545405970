import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import proxyAPI from 'api/proxyAPI';
import {
  HYBRID,
  ICON_PLANE,
  ICON_ATC,
  DARK,
  TAG_NONE,
  TAG_ATC,
  ICON_ATC_TARGET_ONLY,
} from 'components/common/LeafletMap/baseLayers';
import { mapDefaultViewport, zoomDiameterToBounds } from 'utils/mapUtils';
import { faaCategoryOptions } from 'utils/formFieldsHelpers';
import { fetchUser } from './auth';

const DEFAULT_MAP_TYPE = HYBRID;
const DEFAULT_ICON_TYPE = ICON_PLANE;
const DEFAULT_TAG_TYPE = TAG_NONE;

const initialFilters = {
  faa: {
    filterCategory: 'aircraft',
    alias: 'FAA Category',
    value: [''],
    options: [{ value: 'any', name: 'Any' }, ...faaCategoryOptions],
  },
  reg: {
    filterCategory: 'aircraft',
    alias: 'Registration',
    value: [''],
    placeholder: ['ABCD, N65, N6, N'],
    isWildcard: true,
  },
  bsd: {
    filterCategory: 'aircraft',
    alias: 'Based',
    value: [''],
    placeholder: ['KXXX'],
    isWildcard: true,
  },
  typ: {
    filterCategory: 'aircraft',
    alias: 'Type',
    value: [''],
    placeholder: ['A320'],
    isWildcard: true,
  },
  own: {
    filterCategory: 'aircraft',
    alias: 'Owner',
    value: [''],
    placeholder: ['Owner'],
    isWildcard: true,
  },
  opr: {
    filterCategory: 'aircraft',
    alias: 'Operator',
    value: [''],
    placeholder: ['Operator'],
    isWildcard: true,
  },
  direction: {
    filterCategory: 'flight',
    alias: 'INB/OUTB',
    value: [''],
    options: [
      { value: 'any', name: 'Any' },
      { value: 'in', name: 'Inbound' },
      { value: 'out', name: 'Outbound' },
      { value: 'in,out', name: 'Inbound & Outbound' },
    ],
    isWildcard: true,
  },
  ladd: {
    filterCategory: 'flight',
    alias: 'LADD',
    value: [''],
    options: [
      { value: 'any', name: 'Any' },
      { value: 'true', name: 'Enabled' },
      { value: 'false', name: 'Disabled' },
    ],
    isCheckingBoolean: true,
  },
  gda: {
    filterCategory: 'flight',
    alias: 'AIR/GND',
    value: [''],
    options: [
      { value: 'any', name: 'Any' },
      { value: 'A', name: 'AIR' },
      { value: 'G', name: 'GND' },
    ],
  },
  fli: {
    filterCategory: 'flight',
    alias: 'Flight',
    value: [''],
    placeholder: ['ABCD123'],
    isWildcard: true,
  },
  squ: {
    filterCategory: 'flight',
    alias: 'Squawk',
    value: [''],
    placeholder: ['7500,7600,7700'],
    isWildcard: true,
  },
  spd: {
    filterCategory: 'flight',
    alias: 'Speed',
    value: ['', ''],
    placeholder: ['from', 'to'],
    isFromTo: true,
  },
  alt: {
    filterCategory: 'flight',
    alias: 'Altitude',
    value: ['', ''],
    placeholder: ['from', 'to'],
    isFromTo: true,
  },
  vrt: {
    filterCategory: 'flight',
    alias: 'Vertical Rate',
    value: ['', ''],
    placeholder: ['from', 'to'],
    isFromTo: true,
  },
  altg: {
    filterCategory: 'flight',
    alias: 'GNSS Alt.',
    value: ['', ''],
    placeholder: ['from', 'to'],
    isFromTo: true,
  },
  ict: {
    filterCategory: 'debug',
    alias: 'ACFT Category',
    value: [''],
    placeholder: ['HEL'],
    isDebugFilter: true,
  },
  cla: {
    filterCategory: 'debug',
    alias: 'cla parameter',
    value: [''],
    placeholder: [''],
    isDebugFilter: true,
  },
  origin: {
    filterCategory: 'debug',
    alias: 'origin',
    value: [''],
    placeholder: [''],
    isDebugFilter: true,
  },
};

/**
 * @typedef {Object} MapState
 * @property {string} mapType - Type of the base layer on of the baseLayers
 * @property {string} mapIcons - Type of the icons drawn on the map (plane|atc)
 * @property {boolean} tagsLayer - Overlay with live flight info tags
 * @property {boolean} sensorsLayer - Overlay with sensors
 * @property {boolean} weatherLayer - Overlay with weather
 * @property {(number[][]|null)} zoomBounds - One of the zoom presets values
 * @property {{center: number[], zoom: number, bounds: {vpn: number, vps: number, vpe: number, vpw: number}}} viewport ReactLeaflet viewport
 */
const initialState = {
  mapType: DEFAULT_MAP_TYPE,
  iconType: DEFAULT_ICON_TYPE,
  tagType: DEFAULT_TAG_TYPE,
  mapWeatherRadarType: null,
  attributes: {
    tagsLayer: false,
    turnedOffLayer: false,
    sensorsLayer: false,
    weatherLayer: false,
    geofenceLayer: false,
    airportsLayer: false,
    airspacesLayer: false,
    dimmingLayer: false,
  },
  sources: {
    a: true,
    u: true,
    m: true,
    c: true,
    f: false,
    t: false,
  },
  origins: {
    JV: true,
    VT: true,
    UAX: true,
  },
  zoomBounds: null,
  viewport: mapDefaultViewport,
  filters: initialFilters,
  isBasicMap: false,
  aircraftLookup: {
    fetching: false,
    searchResults: [],
    searchTotal: 0,
    aircraftLookupRequestId: '',
    selectedAircraft: null,
  },
};

export const aircraftLookup = createAsyncThunk('map/aircraftLookup', async (query) => {
  return proxyAPI.searchAircrafts(query);
});

export const mapSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setMapType(state, action) {
      state.mapType = action.payload;
    },
    setWeatherRadarType(state, action) {
      const newWeatherRadarType = action.payload;
      if (state.mapWeatherRadarType === newWeatherRadarType) {
        state.mapWeatherRadarType = null;
      } else {
        state.mapWeatherRadarType = action.payload;
      }
    },
    setIconType(state, action) {
      if (action.payload === ICON_ATC || action.payload === ICON_ATC_TARGET_ONLY) {
        state.tagType = TAG_ATC;
        state.mapType = DARK;
      }
      if (action.payload === ICON_PLANE && state.tagType === TAG_ATC) {
        state.tagType = TAG_NONE;
      }
      state.iconType = action.payload;
    },
    setTagType(state, action) {
      state.tagType = action.payload;
    },
    setZoomBounds(state, action) {
      state.zoomBounds = action.payload;
    },
    setViewport(state, action) {
      state.viewport = action.payload;
    },
    toggleLayer(state, action) {
      const { payload: layerName } = action;
      state.attributes[layerName] = !state.attributes[layerName];
    },
    setLayerState(state, action) {
      const {
        payload: { layerName, flag },
      } = action;
      state.attributes[layerName] = flag;
    },
    makeUnavailableLayer(state, action) {
      const { payload: layerName } = action;
      state.attributes[layerName] = null;
    },
    toggleSource(state, action) {
      state.sources[action.payload] = !state.sources[action.payload];
    },
    toggleOrigin(state, action) {
      state.origins[action.payload] = !state.origins[action.payload];
    },
    setSources(state, action) {
      const newSources = action.payload;
      state.sources = { ...state.sources, ...newSources };
    },
    setCenter(state, action) {
      state.viewport.center = action.payload;
    },
    setFilter(state, action) {
      const {
        payload: { filter, valueIndex, value },
      } = action;
      state.filters[filter].value[valueIndex] = value;
    },
    resetFilters(state) {
      state.filters = initialFilters;
    },
    setIsBasicMap(state, action) {
      state.isBasicMap = action.payload;
    },
    setAircraftLookupFetching(state, action) {
      state.aircraftLookup.fetching = action.payload;
      if (action.payload === false) {
        /* Change id on purpose to stop some stuck requests from overriding the empty list */
        state.aircraftLookup.aircraftLookupRequestId = -1;
      }
    },
    setAircraftLookupSelected(state, action) {
      state.aircraftLookup.selectedAircraft = action.payload;
    },
  },
  extraReducers: {
    [fetchUser.fulfilled]: (state, action) => {
      const { airport } = action.payload;
      const { lat, lon } = airport;
      state.viewport.center = [+lat, +lon];
      state.zoomBounds = zoomDiameterToBounds(airport.zoomDiameter2, +lat, +lon);
    },

    [aircraftLookup.pending]: (state, action) => {
      state.aircraftLookup.aircraftLookupRequestId = action.meta.requestId;
    },
    [aircraftLookup.fulfilled]: (state, action) => {
      if (action.meta.requestId !== state.aircraftLookup.aircraftLookupRequestId) {
        return;
      }
      state.aircraftLookup.fetching = false;
      state.aircraftLookup.searchResults = action.payload.data;
      state.aircraftLookup.searchTotal = action.payload.total;
    },
    [aircraftLookup.rejected]: (state, action) => {
      state.aircraftLookup.fetching = false;
    },
  },
});

export default mapSlice.reducer;

export const {
  toggleLayer,
  setLayerState,
  setMapType,
  setWeatherRadarType,
  setIconType,
  setZoomBounds,
  setViewport,
  toggleSource,
  setSources,
  setCenter,
  setFilter,
  resetFilters,
  setTagType,
  setIsBasicMap,
  makeUnavailableLayer,
  toggleOrigin,
  setAircraftLookupFetching,
  setAircraftLookupSelected,
} = mapSlice.actions;
