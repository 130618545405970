import React, { lazy } from 'react';
import Page from 'components/common/Page';
const Activities = lazy(() => import('./Activities'));

const ActivitiesPage = () => {
  return (
    <Page>
      <Activities />
    </Page>
  );
};

export default ActivitiesPage;
