import React, { lazy } from 'react';
import Page from 'components/common/Page';
const WeatherMetaf = lazy(() => import('./WeatherMetaf'));

const WeatherMetafPage = () => {
  return (
    <Page>
      <WeatherMetaf />
    </Page>
  );
};

export default WeatherMetafPage;
