import Cookies from 'js-cookie';

const TOKEN_COOKIE = 'virtower_user_token';

export default {
  setCookie(key, val, options = {}) {
    if (!key || !val) {
      return;
    }
    Cookies.set(key, val, {
      ...this.getCookieOptions({ ...options }),
    });
  },

  getCookieOptions(options = {}) {
    return {
      domain: window.location.hostname,
      path: '/',
      ...options,
    };
  },

  setToken(token, expires) {
    this.setCookie(TOKEN_COOKIE, token, { expires });
  },

  getToken() {
    return this.getCookie(TOKEN_COOKIE);
  },

  getCookie(key) {
    if (!key) {
      return null;
    }
    return Cookies.get(key);
  },

  deleteToken() {
    Cookies.remove(TOKEN_COOKIE, {
      ...this.getCookieOptions(),
    });
  },
};
