import React, { useEffect, useMemo, useRef } from 'react';
import { useTable } from 'react-table';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSocketService } from 'api/SocketContext';
import { toggleControl } from 'redux/modules/modal';
import { deleteAlarm } from 'redux/modules/notifications';
import { MAP_CONTROLS } from 'utils/globalConstants';
import { useLocalDateFormatter, useLocalTimeFormatter } from 'hooks/useFormatters';
import CustomTable from 'components/common/CustomTable';
import TableAlarmsRemoveButton from 'components/common/CustomTable/TableAlarmsRemoveButton';
import DNDModal from 'components/base/DNDModal';

import './AlarmsModal.css';

const useOutsideClick = (ref, callback) => {
  useEffect(() => {
    if (!callback) return null;

    const handleClickOutside = (event) => {
      const isTargetAlarmsButton = event.target?.className?.contains?.('alarms-button') >= 0;

      if (ref.current && !ref.current.contains(event.target) && !isTargetAlarmsButton) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};

const AlarmsModal = ({ top = 0, left = 0, width = 520, height = 300 }) => {
  const dispatch = useDispatch();
  const socketService = useSocketService();

  const timeFormatter = useLocalTimeFormatter();
  const dateFormatter = useLocalDateFormatter();
  const { alarmList } = useSelector((state) => state.notifications);
  const { token } = useSelector((state) => state.auth.userInfo);

  const alarmsData = useMemo(() => {
    return alarmList.map(({ id, createdAt, registration, eventName }) => {
      return {
        id,
        registration,
        date: dateFormatter.format(new Date(createdAt)),
        time: timeFormatter.format(new Date(createdAt)),
        eventName,
      };
    });
  }, [alarmList, timeFormatter, dateFormatter]);

  const columns = useMemo(
    () => [
      {
        Header: 'Registration',
        accessor: 'registration',
        width: 120,
      },
      {
        Header: 'Date',
        accessor: 'date',
        width: 100,
      },
      {
        Header: 'Time (LOC)',
        accessor: 'time',
        width: 80,
      },
      {
        Header: 'Activity',
        accessor: 'eventName',
        width: 160,
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data: alarmsData }, (hooks) => {
    hooks.visibleColumns.push((cols) => [
      ...cols,
      {
        Header: '',
        accessor: 'col5',
        width: 40,
        Cell: TableAlarmsRemoveButton,
        onClick: (entity) => {
          socketService.alarmChecked(entity.id, token);
          dispatch(deleteAlarm(entity));
        },
      },
    ]);
  });

  const ref = useRef();

  useOutsideClick(ref, () => {
    dispatch(toggleControl(MAP_CONTROLS.ALARMS));
  });

  return (
    <DNDModal
      outsideClickRef={ref}
      id={MAP_CONTROLS.ALARMS}
      left={left}
      top={top}
      title="Alarms"
      width={width}
      height={height}
    >
      <div className="alarms-table__wrapper">
        <CustomTable tableName="alarms" tableInstance={tableInstance} className="alarms-table" />
      </div>
    </DNDModal>
  );
};

AlarmsModal.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default AlarmsModal;
