import React, { lazy } from 'react';
import Page from 'components/common/Page';
const AuditLog = lazy(() => import('./AuditLog'));

const AuditLogPage = () => {
  return (
    <Page>
      <AuditLog />
    </Page>
  );
};

export default AuditLogPage;
