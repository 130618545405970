import { useEffect, useState, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useSocketService } from 'api/SocketContext';

/**
 * Hook to subscribe to ws topics
 * @param {*} topicName The name of the topic that you want to subscribe to
 * @param {*} params Should be memoized! Will be send to server together with auth data
 * @returns [data, forceUpdateFunction]
 */
export function useWebsocketSubscription(topicName, params) {
  const socket = useSocketService();
  const [forceUpdatevalue, forceUpdate] = useReducer((x) => x + 1, 0);
  const { token } = useSelector((state) => state.auth.userInfo);
  const [data, setData] = useState();

  useEffect(() => {
    return socket.subscribeTopic(topicName, token, params);
  }, [socket, token, topicName, params, forceUpdatevalue]);

  useEffect(() => {
    return socket.onTopic(topicName, (newData) => {
      setData(newData);
    });
  }, [socket, topicName]);

  return [data, forceUpdate];
}
