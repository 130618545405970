import React, { lazy } from 'react';
import Page from 'components/common/Page';
const ExternalBasedAircraft = lazy(() => import('./ExternalBasedAircraft'));

const ExternalBasedAircraftPage = () => {
  return (
    <Page>
      <ExternalBasedAircraft />
    </Page>
  );
};

export default ExternalBasedAircraftPage;
