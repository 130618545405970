/**
 * Function for detecting how user interacts with interface
 * If user uses keyboard navigation add class 'utilitfyfocus'
 * otherwise 'pointerfocus'
 * @returns { () => void } callback for removing listeners
 */
export default function addFocusTypeDetection() {
  const setUtilityFocus = () => {
    document.body.classList.add('utilityfocus');
    document.body.classList.remove('pointerfocus');
  };
  const setPointerFocus = () => {
    document.body.classList.add('pointerfocus');
    document.body.classList.remove('utilityfocus');
  };

  document.addEventListener('keydown', setUtilityFocus);
  document.addEventListener('mousedown', setPointerFocus);

  return () => {
    document.removeEventListener('keydown', setUtilityFocus);
    document.removeEventListener('mousedown', setPointerFocus);
  };
}
