import { axiosAPIInstance } from './api';

// prettier-ignore
export function aircraftInfoTupleToObject(aircraftInfoTuple) {
  const [uti,hex,lat,lon,alt,altg,spd,vrt,trk,cat,src,gda,fli,cla,cou,squ,clnt,id,reg,faa,ict,typ,off,own,opr,img,dep,arr,bsd,origin,tru,ns,ladd ] = aircraftInfoTuple;
  return {uti,hex,lat,lon,alt,altg,spd,vrt,trk,cat,src,gda,fli,cla,cou,squ,clnt,id,reg,faa,ict,typ,off,own,opr,img,dep,arr,bsd,origin,tru,ns,ladd};
}
class ProxyAPI {
  constructor(axiosInstance) {
    this.axiosInstance = axiosInstance;
  }
  /**
   * @typedef Bounds
   * @param {number} vpn Northern border of window
   * @param {number} vps Southern border of window
   * @param {number} vpw Western border of window
   * @param {number} vpe Eastern border of window
   */

  /**
   * @typedef Aircraft
   * @property {string} hex ICAO Mode-S or Flarm ID, in Hex format. Uppercase for Mode-S and Flarm, lowercase for TIS-B
   * @property {number} lat Flight data: Latitude (-90 to 90º)
   * @property {number} lon Flight data: Longitude (-180 to 180º)
   * @property {(number|null)} trk Flight data: True track (-180 to 180º)
   * @property {(number|null)} alt Flight data: Barometric uncalibrated altitude (ft)
   * @property {(number|null)} spd Flight data: Ground Speed (kt)
   * @property {(number|null)} vrt Flight data: Vertical Rate (ft/min)
   * @property {(string|null)} cat Aircraft category		As reported in ADS-B or FLARM. See table below.
   * @property {(string|null)} typ Type designator
   * @property {(string|null)} reg Registration from open database or if not available automatically deduced for some countries with known mapping (FR, BE, PT, CH, US and CA)
   * @property {string} ava Flight data: available sources. One letter per available source. A=ADSB, M=proxy MLAT, F=FlightAware MLAT, O=OGN, L=FLARM
   * @property {string} src Source	The current preferred/selected source (A|M|F|O|L). See ava
   * @property {string} gda Airborne/Ground (A|G|a|g) G for on ground, A for airborne (if TIS-B then lowercase g or a)
   * @property {number} uti Time of last message: Seconds since UNIX epoch
   * @property {number} ns Time of last message: Nanoseconds since Second epoch
   * @property {number} cla
   * @property {string} fli Flight Identification. From ADS-B or BDS2,0 as specified on ICAO Annex 10, Volume IV, Table 3-9; "?" when A/C reports loss of interface
   * @property {number} tmp Temperature (ºC)Synthetic value calculated from several fields. Might not be always reliable
   * @property {number} wsp Wind Speed (kt)	Synthetic value calculated from several fields. Might not be always reliable
   * @property {number} wdi Wind from Direction (0-360º)	0	Synthetic value calculated from several fields. Might not be always reliable
   * @property {string} dep Departure airport
   * @property {string} arr	Arrival airport
   * @property {string} opr Operator		Operator from open database
   * @property {number} dis Distance (km or NM depending on configuration)		To the Receiver GNSS location
   * @property {number} dbm Approximate signal level in dBm
   * @property {string} cou Country		According to Mode-S allocation block
   * @property {string} squ Mode-A (Squawk)		While this is a Mode-A code, it can only be correlated in Mode-S targets. Mode-A or Mode-AC targets are not displayed in decoded formats
   * @property {number} tru Trust count		Number of Mode-S frames successfully decoded from this A/C. Statistically it's possible that for a very low count the A/C does not actually exist
   * @property {number} lla Age of last Position (s)
   * @property {number} mop ADS-B MOPS version		null=no ADS-B frames received, 0=default for unconfirmed version, 1 or 2 for corresponding confirmed version
   * @property {number} alr Alert status		0=no alert, 1=temporary (Squawk changed previous 18s), 2=mode-s alert, 3=permanent (Squawk 7500,7600,7700). Since Mode-S DF4,5,20,21 do not distinguish between temporary and permanent alert, the value 2 is used to mean either 1 or 3 and happens for non ADS-B A/C targets
   * @property {boolean} spi SPI (Squawk ident)
   * @property {number} tcm ACAS/TCAS status	0, alert only 2	0=not operational, 1=operational, 2=alert
   * @property {boolean} ape Autopilot engaged
   * @property {number} pic Asterix PIC. EUROCONTROL-SPEC-0149-12 (ASTERIX Cat 021 Ed 2.4)
   * @property {number} nacp ADS-B NACp
   * @property {number} sil ADS-B SIL
   * @property {number} sda ADS-B SDA
   * @property {number} altg GNSS elipsoidal altitude (ft)	Either directly reported or from baro+difference
   * @property {number} qnhs Selected QNH. Actual value entered by the A/C crew
   * @property {number} alts Selected Altitude.	Actual value entered by the A/C crew
   * @property {number} hdgs Selected Heading. Actual value entered by the A/C crew
   * @property {number} pest
   * @property {number} nocl
   * @property {string[]} clnt list of visible to plain stations
   * @property {string} faa faaCategory of the aircraft
   * @property {string} ict iconType defined by user
   * @property {bool} off shows if aircraft has turned off ads-b
   * @property {string} own owner details
   * @property {string} img path to image of aircraft
   * @property {string} bsd airport icao where aircraft is based
   * @property {string} ladd indicates if aircraft is on ladd list
   */

  /**
   * Fetch aircrafts based on params
   * @async
   * @param {Object} params Fetch aircrafts
   * @param {Bounds} params.bounds Bounds of the map {@link Bounds}
   * @param {Object} params.sources Enabled sources
   * @param {Object} params.activeAircrafts Active aircrafts
   * @param {Object} params.offline Load offline planes
   * @returns {Promise<Aircraft[]>} array of aircrafts {@link Aircraft}
   */
  async getAircrafts(params) {
    const { bounds = {}, offline, activeAircrafts, isPublic, selectedAircraft } = params;
    const queryParams = new URLSearchParams();

    if (offline) queryParams.append('offline', 1);
    if (activeAircrafts) activeAircrafts.forEach((ac) => queryParams.append('acftincl', ac.hex));
    if (selectedAircraft) queryParams.append('acftselected', selectedAircraft.hex);

    const { vpn = 1, vpe = 1, vps = 1, vpw = 1 } = bounds;
    if (vpn && vpe && vps && vpw) {
      queryParams.append('vpn', vpn);
      queryParams.append('vps', vps);
      queryParams.append('vpw', vpw);
      queryParams.append('vpe', vpe);
    }

    if (isPublic) {
      queryParams.append('is_public', true);
    }

    const response = await this.axiosInstance.get(`/proxy/aircrafts?${queryParams.toString()}`);
    const aircraftData = response.data?.map(aircraftInfoTupleToObject);

    return aircraftData;
  }

  async searchAircrafts(query) {
    const queryParams = new URLSearchParams();
    queryParams.append('query', query?.toUpperCase());

    const response = await this.axiosInstance.get(`/proxy/aircrafts/search?${queryParams.toString()}`);
    const searchResults = response.data;

    return searchResults;
  }

  /**
   * @typedef Sensor
   * @property {string} id sensor id
   * @property {number} lat Latitude (-90 to 90º)
   * @property {number} lon Longitude (-180 to 180º)
   * @property {boolean} active Active
   * @property {number} ac
   * @property {number} alt Barometric uncalibrated altitude (ft)
   * @property {number} altg GNSS Altitude (ft)
   * @property {boolean} anto
   * @property {boolean} ants
   * @property {number} conn
   * @property {boolean} gnss
   * @property {string} hwtyp
   * @property {number} iBy Bytes/sec incoming
   * @property {number} iMsg Msgs/sec incoming
   * @property {number} iPackets
   * @property {number} iTotal Total MB incoming
   * @property {number[]} latency
   * @property {number} latency_90
   * @property {number} latency_max
   * @property {number} latency_min
   * @property {number} latency_sum
   * @property {number} login Login
   * @property {number} logout Logout
   * @property {number} qRes
   * @property {number} sats
   * @property {string} swtyp
   * @property {number} tUTC
   * @property {number} temp Temperature
   * @property {string} ver Version
   */

  /**
   * Fetch sensors based on params
   * @async
   * @param {Bounds} params Bounds of the map {@link Bounds}
   * @returns {Promise<Sensor[]>} array of sensors {@link Sensor}
   */
  async getSensors(params = {}) {
    const { origins } = params;
    const { vpn = 47, vpe = -57, vps = 14, vpw = -127 } = params.bounds || {};
    const queryParams = new URLSearchParams();

    Object.entries(origins).forEach(([org, value]) => {
      queryParams.append(`origin_${org}`, value);
    });

    queryParams.append('vpn', vpn);
    queryParams.append('vps', vps);
    queryParams.append('vpw', vpw);
    queryParams.append('vpe', vpe);

    const response = await this.axiosInstance.get(`/proxy/clients?${queryParams.toString()}`);
    const ORIGIN_MAP = {
      UX: 'UAX',
    };
    const sensorsAdapted = response?.data?.response?.map((sensor) => {
      const id = sensor.device?.id;
      const lat = sensor.pos?.lat;
      const lon = sensor.pos?.lon;
      const temp = sensor.temp;
      const origin = ORIGIN_MAP[sensor.origin] ? ORIGIN_MAP[sensor?.origin] : sensor.origin;
      return {
        ...sensor,
        id,
        lat,
        lon,
        temp,
        origin,
      };
    });
    return sensorsAdapted;
  }

  /**
   * Path aircraft info
   * @async
   * @param {Aircraft} aircraftInfo Fields of aircraft info that should be updated {@link Aircraft}
   * @returns {Promise<void>} success code
   */
  async patchAircraft(aircraftInfo) {
    const response = await this.axiosInstance.patch(`/proxy/aircraft`, aircraftInfo);
    return response.data;
  }

  /**
   * Undo last change in aircraft info
   * @async
   * @returns {Promise<void>} success code
   */
  async undoAircraft() {
    const response = await this.axiosInstance.post(`/proxy/aircraft/undo`);
    return response.data;
  }

  /**
   * @typedef FlightpathPoint
   * @property {number} uti Time of last message: Seconds since UNIX epoch
   * @property {number} lat Latitude (-90 to 90º)
   * @property {number} lon Longitude (-180 to 180º)
   * @property {(number|null)} alt Barometric uncalibrated altitude (ft)
   */

  /**
   * @typedef Flightpath
   * @property {string} hex ICAO Mode-S or Flarm ID, in Hex format. Uppercase for Mode-S and Flarm, lowercase for TIS-B
   * @property {string} src Source. A - ADS-B, M - MLAT
   * @property {FlightpathPoint[]} fpth Array of flightpath points {@link FlightpathPoint}
   */

  /**
   * Fetch flightpath for aircraft
   *
   * @async
   * @param {string[]} hexList list of hex to load
   * @property {string} src Source. A - ADS-B, M - MLAT
   * @returns {Promise<Flightpath[]>} flightpath {@link Flightpath}
   */
  async getFlightpath(hexList, lookback) {
    const response = await this.axiosInstance.post(`/proxy/flightpath`, {
      hex: hexList,
      lookback,
    });
    return response.data;
  }
}

const ApiInstance = new ProxyAPI(axiosAPIInstance);

export default ApiInstance;
