import React, { lazy } from 'react';
import Page from 'components/common/Page';
const Operations = lazy(() => import('./Operations'));

const OperationsPage = () => {
  return (
    <Page>
      <Operations />
    </Page>
  );
};

export default OperationsPage;
